import React, {useEffect} from 'react';
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {green} from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    card: {
        minWidth: 275,
        minHeight: 190,
        backgroundColor: "rgb(248, 248, 248)",
        border: "1px solid rgb(214, 218, 222);",
    },
    cardContentWithTabs: {
        padding: 0
    },
    tabPanel:{
        padding: 20
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

export default function ReconcileActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [line, setLine] = React.useState(props.line);
    const [showButton, setShowButton] = React.useState(false);
    const [isMatchedJob, setIsMatchedJob] = React.useState(false);
    useEffect(() => {
        if (line.matchingJob) {
            if (typeof line.matchingJob.uid !== "undefined") {
                if (line.matchingJob.matchedJob) {
                    setIsMatchedJob(line.matchingJob.matchedJob);
                } else {
                    setShowButton(true);
                }
            }
        }
    }, []);

    return (
        <div className={classes.wrapper}>
        {showButton ?
        (
            <div style={{textAlign: "center"}}>
                <Button variant="outlined" color="primary" className={classes.button} onClick={props.onOkClick} disabled={props.isLoading}>
                    OK
                </Button>
                {props.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            ) : null}
        {isMatchedJob ?
            (
                <div style={{textAlign: "center"}}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={props.onUndoClick} disabled={props.isLoading}>
                        Undo
                    </Button>
                    {props.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            ) : null

        }
        </div>
    );
}