import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from "@material-ui/core/Icon";
import clsx from 'clsx';
import Link from "@material-ui/core/Link";
import { loadCSS } from 'fg-loadcss';
import Grid from "@material-ui/core/Grid";
import CurrencyFormat from "../_helpers/CurrencyFormat";
import Moment from "react-moment";

const useStyles = makeStyles(theme =>({
    card: {
        minWidth: 275,
        backgroundColor: "rgb(248, 248, 248)",
        border: "1px solid rgb(214, 218, 222);",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,

    },
    jobTitle: {
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: 12
    },
    icon: {
        fontSize: "16px",
        marginLeft: '5px',
    },
    invoiceDetail: {
        borderRight: '1px solid rgb(214, 218, 222);'
    },
    typeDetail: {
        fontSize: "12px"
    },
    typeAmount: {
        fontSize: "12px",
        fontWeight: 700
    },
    buttonLeftIcon: {
        marginRight: "5px",
        fontWeight: 500,
        fontSize: "12px"
    },
    moreBtnGrid: {
        textAlign: "right"
    },
    otherMatches: {
        position: "absolute",
        color: "#20a8d8",
        fontWeight: 400,
        fontSize: "14px",
        bottom: 0,
        right: "5px",
    },
    linkedJobTitle: {
        fontSize: "11px"
    }

}));

export default function MatchTabDetail(props) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const [job, setJob] = React.useState(props.job);
    const [line, setLine] = React.useState(props.line);
    return (

            <Grid container spacing={5} key={job.uid}>
                <Grid item xs={9} className={classes.invoiceDetail}>
                    <Typography variant="subtitle1" component="h2" className={classes.jobTitle}>
                        {job.name}
                        {job.url ?
                            <Link href={job.url} target="_blank">
                                <Icon className={clsx(classes.icon, 'fa fa-external-link-alt')}/>
                            </Link>
                            : null

                        }
                    </Typography>
                    {job.linkedJobs.map((linked_job, i) =>
                        <Link href={linked_job.url} target="_blank">
                           <Typography variant="body1" className={classes.linkedJobTitle}>Linked: {linked_job.name}</Typography>
                        </Link>
                    )}
                    <Typography className={classes.typeDetail} color="textSecondary">
                        Created: <Moment format="D MMM YYYY">{job.createdAt}</Moment>
                    </Typography>
                    <Typography className={classes.typeDetail} color="textSecondary">
                        Owner: {job.ownerName}
                    </Typography>
                    <Typography className={classes.typeDetail} color="textSecondary">
                        Client: {job.client}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.typeAmount}>
                       Sale Value: <CurrencyFormat value={job.value}/>
                    </Typography>
                    <Typography className={classes.typeAmount}>
                        Reconciled: {line.invoice.invoiceType == "ACCREC" ? <CurrencyFormat value={job.totalInvoices}/>
                                    : <CurrencyFormat value={job.totalPurchases}/>}
                    </Typography>
                    {props.otherMatchesCount > 0 ?
                    (<Typography className={classes.otherMatches}>
                        {props.otherMatchesCount} other matches
                    </Typography>)
                    : (<div></div>)
                    }
                </Grid>
            </Grid>

    );
}