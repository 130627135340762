import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import NoSsr from "@material-ui/core/NoSsr";
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import API from "../api";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    grid:{
        padding: 5
    },
    textField: {
        width: "100%",
    },
    dense: {
        marginTop: 5,
    },
    menu: {
        width: 200,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const JobSchema = Yup.object().shape({
    value: Yup.number().positive().required('Required'),
    source: Yup.string().required('Required'),
    sourceId: Yup.string().required('Required'),
    ownerName: Yup.string().required('Required'),
    ownerEmail: Yup.string().email('Invalid email').required('Required'),
});
export default function JobCreateForm(props) {
    const classes = useStyles();
    async function createJob(values, actions) {
        values.invoiceLineId = props.line.id;
        await API.post('jobs-reconcile/', values)
            .then(function (response) {
                //console.log(response);
                actions.setSubmitting(false);
                props.hideRow();
            })
            .catch(function (error) {
                console.log(error);
                actions.setSubmitting(false);
            });
    }
    return (
        <Formik
            initialValues={{
                name: props.line.invoice.reference,
                value: "",
                source: "",
                sourceId: "",
                ownerName: "",
                ownerEmail: "",
            }}
            validationSchema={JobSchema}
            onSubmit={(values, actions ) => {
                setTimeout(() => {
                    createJob(values, actions);
                }, 400);
            }}
        >
            {({ errors, touched , isSubmitting }) => (
        <Form className={classes.container} autoComplete="off">
            {errors.name ? (
                <div>{errors}</div>
            ) : null}
            <Grid container spacing={5} className={classes.grid}>
                <Grid item xs={6} className={classes.grid}>

                    <Field
                        id="outlined-dense"
                        label="Name"
                        name="name"
                        className={clsx(classes.textField, classes.dense)}
                        margin="dense"
                        component={TextField}
                        variant="outlined"
                    />

                    <Field
                        id="outlined-dense"
                        label="Source"
                        name="source"
                        className={clsx(classes.textField, classes.dense)}
                        margin="dense"
                        component={TextField}
                        variant="outlined"
                    />
                    <Field
                        id="outlined-dense"
                        label="Owner Name"
                        name="ownerName"
                        className={clsx(classes.textField, classes.dense)}
                        margin="dense"
                        component={TextField}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6} className={classes.grid}>
                    <Field
                        id="outlined-dense"
                        label="Value"
                        name="value"
                        className={clsx(classes.textField, classes.dense)}
                        margin="dense"
                        component={TextField}
                        variant="outlined"
                    />
                    <Field
                        id="outlined-dense"
                        label="Source Id"
                        name="sourceId"
                        className={clsx(classes.textField, classes.dense)}
                        margin="dense"
                        component={TextField}
                        variant="outlined"
                    />
                    <Field
                        id="outlined-dense"
                        label="Owner Email"
                        name="ownerEmail"
                        className={clsx(classes.textField, classes.dense)}
                        margin="dense"
                        component={TextField}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Button variant="outlined" className={classes.button} onClick={props.handleFindClick}>
                Find
            </Button>
            <Button type="submit" color="primary" variant="outlined" className={classes.button} disabled={isSubmitting}>
                Reconcile
            </Button>
        </Form>
            )}
        </Formik>
    );
}