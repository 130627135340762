import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import API from "../api";
import Button from "@material-ui/core/Button";
import JobLines from "./JobLines";
import { green } from '@material-ui/core/colors';
import CircularProgress from "@material-ui/core/CircularProgress";
import JobDetailGraph from "./JobDetailGraph";
import JobDetailSummary from "./components/SummaryTable";
import JobKeyIndicatiors from "./components/KeyIndicators";
import StatusBtn from "./components/StatusBtn";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        paddingTop: 0,
        backgroundColor: "rgb(231, 230, 230)"
    },
    table: {
        minWidth: 650,
        tableLayout: "fixed",
    },
    linesContainer: {
        marginTop: theme.spacing(3)
    },
    linesTitle: {
        marginBottom: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    },
    statusBtnContainer:{
        textAlign: "right"
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    jobTitleContainer: {
        padding: 15,
    },
    jobTitle: {
        fontSize: 18,
        fontWeight:700,
    },
    jobOwnerName: {
        fontSize: 18,
        fontWeight:300,
    },
    header: {
        borderBottom: "2px solid #807a7a"
    },
    graphContainer:{
        marginTop: theme.spacing(2)
    },
    mergeLabel: {
        backgroundColor: "orange",
        marginLeft: theme.spacing(2)
    }
}));

export default function JobDetailPanel(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(false);
    const [job, setJob] = React.useState(null);
    const [btnLoading, setBtnLoading] = React.useState(false);
    const [isJobUpdated, setIsJobUpdated] = React.useState(false);
    useEffect(() => {
        // Update the document title using the browser API
        fetchData();

    }, [btnLoading]);

    async function fetchData(page){
        setIsLoading(true);
        //console.log("Fetching Data");
        let url = `job-detail/${props.jobId}/`;
        await API.get(url)
            .then(function (response) {
                //console.log(response);
                setJob(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                if(error.response.status === 404){
                    if(props.isList){
                        props.tableFetchData();
                    }
                    else {
                        return props.history.push("/error");
                    }
                }
                //console.log();
                setIsLoading(false);
            });
    }
    function handleCompleteBtnClick(){
        changeStatus(1);
    }
    function handleActiveBtnClick(){
        changeStatus(0);
    }
    async function changeStatus(status) {
        setBtnLoading(true);
        let url = `job-detail/${props.jobId}/`;
        job["status"] = status;
        await API.patch(url, job)
            .then(function (response) {
                setJob(response.data);
                setBtnLoading(false);
                if(props.isList) {
                    props.rowData[13].props.updateValue(job["status"]);
                }

            })
            .catch(function (error) {
                console.log(error);
                setBtnLoading(false);
            });
    }
    function handleRefresh(){
        fetchData();
    }
    function closePanel() {
        props.tableFetchData();
    }
    function handleLoading(isLoading) {
        setIsLoading(isLoading);
    }
    function handleJobUpdate(job) {
        setIsJobUpdated(true);
        setJob(job);
        setIsJobUpdated(false);
    }
    return (
        <div>
            {!isLoading ?
            <Paper elevation={4} className={classes.root}>

                        <Grid container className={classes.header} spacing={0}>
                            <Grid item xs={9} className={classes.jobTitleContainer}>
                                {job ?
                                    <div>
                                        <Typography className={classes.jobTitle} display="inline">{job.name}</Typography>
                                        <Typography className={classes.jobOwnerName} display="inline"> | {job.ownerName}</Typography>
                                        {job.linkedJobs.length > 0 ?
                                            <Button variant="button" color="primary" className={classes.mergeLabel}>Merged Job</Button>
                                            : null}
                                    </div>
                                    : null}
                            </Grid>
                            <Grid item xs={3} className={classes.statusBtnContainer}>
                                <StatusBtn job={job} rowData={props.rowData} handleJobUpdate={handleJobUpdate} handleRefresh={handleRefresh} handleLoading={handleLoading} closePanel={closePanel} isJobUpdated={isJobUpdated} {...props}/>
                            </Grid>
                        </Grid>
                <div className={classes.summaryContainer}>
                    <JobDetailSummary job={job} isLoading={isLoading} isJobUpdated={isJobUpdated} rowData={props.rowData} handleJobUpdate={handleJobUpdate}/>
                </div>
                <div className={classes.keyIndicatorsContainer}>
                    <JobKeyIndicatiors job={job} isLoading={isLoading} isJobUpdated={isJobUpdated}/>
                </div>
                {job ?
                    job.invoices.length > 0 ?
                        <div className={classes.linesContainer}>
                            <div className={classes.linesTitle}>
                                <Typography variant="h6">
                                    Invoices
                                </Typography>
                            </div>
                            <JobLines lines={job.invoices} job={job} handleRefresh={handleRefresh} />
                        </div>

                    : null
                : null
                }
                {job ?
                    job.purchases.length > 0 ?
                        <div className={classes.linesContainer}>
                            <div className={classes.linesTitle}>
                                <Typography variant="h6">
                                    Purchases
                                </Typography>
                            </div>
                            <JobLines lines={job.purchases} job={job} handleRefresh={handleRefresh} />
                        </div>
                        : null
                 : null
                }
                <div className={classes.graphContainer}>
                    <JobDetailGraph job={job} isLoading={isLoading} isJobUpdated={isJobUpdated}/>
                </div>
            </Paper>
                :
                <div className={classes.progressDiv}>
                    <CircularProgress className={classes.progress} />
                </div>
            }
        </div>
    );
}