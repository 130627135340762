import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import {green, red} from "@material-ui/core/colors";

const useStyles = makeStyles(theme =>({
    positive: {
       color: "#2e7d32"
    },
    negative: {
        color: "#c62828"
    }

}));

export default function CurrencyFormat(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState("$0");
    const [className, setClassName] = React.useState("");
    React.useEffect(() => {

        if(props.value){
            let value = Intl.NumberFormat('en-au', {
                style: 'currency',
                currency: 'AUD'
            }).format(Math.abs(props.value));
            if(props.highlight){
                if(props.value >= 0){
                    setClassName(classes.positive);
                }
                else {
                    setClassName(classes.negative);
                }
            }
            if(props.value < 0){
                value = `(${value})`
            }
            setValue(value);
        }
    }, [props.value]);
    return (
        <span className={`${className}`}>
            {value}
        </span>
    )
}
