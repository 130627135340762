import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    table: {
        maxWidth: 300,
    },
}));


const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        maxWidth: "fit-content"
    },
}))(Tooltip);


export default function InvoiceTooltip(props) {
    const classes = useStyles();
    const [line, setLine] = React.useState(props.line);
    const [invoice, setInvoice] = React.useState(props.line.invoice);
    const [open, setOpen] = React.useState(false);
    function handleClick() {
        setOpen(!open);
    }
    function cickOutside() {
        setOpen(false);
    }
    const MoreButton = React.forwardRef((props, ref) => {
       return (
           <ClickAwayListener key={line.id} onClickAway={cickOutside}>
               <Button size="small" ref={ref}  onClick={handleClick}>More</Button>
           </ClickAwayListener>
       )
    });
    return (
        <div>

                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Paper className={classes.root}>
                                <Table className={classes.table}>
                                    <TableBody>
                                    <TableRow key={`${line.id}_inv_number`}>
                                        <TableCell component="th" scope="row">
                                            Invoice Number
                                        </TableCell>
                                        <TableCell align="left">{invoice.number}</TableCell>
                                    </TableRow>
                                    <TableRow key={`${line.id}_inv_date`}>
                                        <TableCell component="th" scope="row">
                                            Invoice Date
                                        </TableCell>
                                        <TableCell align="left">{invoice.invoiceDate}</TableCell>
                                    </TableRow>
                                    <TableRow key={`${line.id}_inv_type`}>
                                        <TableCell component="th" scope="row">
                                            Invoice Type
                                        </TableCell>
                                        <TableCell align="left">{invoice.invoiceType}</TableCell>
                                    </TableRow>
                                    <TableRow key={`${line.id}_inv_reference`}>
                                        <TableCell component="th" scope="row">
                                            Invoice Reference
                                        </TableCell>
                                        <TableCell align="left">{invoice.reference}</TableCell>
                                    </TableRow>
                                    <TableRow key={`${line.id}_inv_contact`}>
                                        <TableCell component="th" scope="row">
                                            Invoice Contact
                                        </TableCell>
                                        <TableCell align="left">{invoice.contactName}</TableCell>
                                    </TableRow>
                                    <TableRow key={`${line.id}_inv_total`}>
                                        <TableCell component="th" scope="row">
                                            Invoice Total
                                        </TableCell>
                                        <TableCell align="left">{invoice.total}</TableCell>
                                    </TableRow>

                                    <TableRow key={`${line.id}_description`}>
                                        <TableCell component="th" scope="row">
                                            Line Description
                                        </TableCell>
                                        <TableCell align="left">{line.description}</TableCell>
                                    </TableRow>

                                    </TableBody>
                                </Table>
                            </Paper>
                        </React.Fragment>
                    }
                    open={open}
                    placement="bottom"
                    interactive
                >

                    <MoreButton/>

                </HtmlTooltip>

        </div>
    );
}