import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CurrencyFormat from "../_helpers/CurrencyFormat";
import moment from "moment";


const useStyles = makeStyles(theme => ({

}));


export default function JobDetailGraph(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    const [job, setJob] = React.useState(props.job);
    const [data, setData] = React.useState({});
    const [options, setOptions] = React.useState({}); 

    useEffect(() => {
        if(props.job){
            let data = [];
            let date_list = [];
            date_list.push(new Date(props.job.createdAt));
            if(!props.job.completedAt){
                //Active
                date_list.push(new Date());
            }
            else{
                date_list.push(new Date(props.job.completedAt));
            }
            let inv_data = {};
            inv_data['name'] = "Invoices";
            inv_data['color'] = "rgb(136, 191, 77)";
            inv_data['borderColor'] = "rgb(87,124,53)";
            inv_data['type'] = "column";
            inv_data['data'] = []
            props.job.invoices.map(function (line, i){
                // inv_data['data'].push([new Date(line.invoice.invoiceDate).getTime(), parseFloat(line.lineAmount)]);
                inv_data['data'].push({x: new Date(line.invoice.invoiceDate).getTime(), y: parseFloat(line.lineAmount), contact_name: line.invoice.contactName});
                date_list.push(new Date(line.invoice.invoiceDate));
            });
            props.job.invoicesAfterCompletion.map(function (line, i){
                // inv_data['data'].push([new Date(line.invoice.invoiceDate).getTime(), parseFloat(line.lineAmount)]);
                inv_data['data'].push({x: new Date(line.invoice.invoiceDate).getTime(), y: parseFloat(line.lineAmount), contact_name: line.invoice.contactName});
                date_list.push(new Date(line.invoice.invoiceDate));
            });

            let pur_data = {};
            pur_data['name'] = "Purchases";
            pur_data['color'] = "rgb(239, 140, 140)";
            pur_data['borderColor'] = "rgb(163,67,67)";
            pur_data['type'] = "column";
            pur_data['data'] = []
            props.job.purchases.map(function (line, i){
                // pur_data['data'].push([new Date(line.invoice.invoiceDate).getTime(), parseFloat(line.lineAmount)*-1])
                pur_data['data'].push({x: new Date(line.invoice.invoiceDate).getTime(), y:parseFloat(line.lineAmount)*-1, contact_name: line.invoice.contactName});
                date_list.push(new Date(line.invoice.invoiceDate));
            });
            props.job.purchasesAfterCompletion.map(function (line, i){
                // pur_data['data'].push([new Date(line.invoice.invoiceDate).getTime(), parseFloat(line.lineAmount)*-1])
                pur_data['data'].push({x: new Date(line.invoice.invoiceDate).getTime(), y: parseFloat(line.lineAmount)*-1, contact_name: line.invoice.contactName});
                date_list.push(new Date(line.invoice.invoiceDate));
            });
           
            var min = date_list.reduce(function (a, b) { return a < b ? a : b; }); 
            var max = date_list.reduce(function (a, b) { return a > b ? a : b; });
            let all_data = inv_data['data'].concat(pur_data['data']);
            all_data = all_data.sort(function(a, b) { 
                return a.x > b.x ? 1 : -1;
            });
            let line_data = {};
            let margin = 0;
            line_data['name'] = "Margin";
            line_data['color'] = "rgb(80, 158, 227)";
            line_data['borderColor'] = "rgb(80, 158, 227)";
            line_data['data'] = [];
            line_data['type'] = "line";
            line_data['step'] = true;
            all_data.forEach((item, idx) => {
                margin = margin+item.y;
                if (idx === all_data.length - 1){
                    if(props.job.completedAt){
                        let completeAtobj = new Date(props.job.completedAt);
                        if(item.x < completeAtobj){
                            line_data['data'].push({x: item.x, y: margin, label: margin});
                            line_data['data'].push({x: completeAtobj, y: margin, label: margin, dataLabels: {enabled: true}});
                        }
                        else {
                            line_data['data'].push({x: item.x, y: margin, label: margin, dataLabels: {enabled: true}});
                        }
                    }
                    else {
                        line_data['data'].push({x: item.x, y: margin, label: margin, dataLabels: {enabled: true}});
                    }
                }
                else {
                    line_data['data'].push({x: item.x, y: margin, label: margin});
                }
            });
            data = [inv_data, pur_data, line_data];
            //console.log(data);
            //console.log("asdasd");
            //console.log(line_data);
            //console.log(all_data);
            //console.log(min, max);
            let options = {
                    chart: {
                        type: 'column',
                        backgroundColor: 'rgb(231, 230, 230)',
                    },
                    title:{
                        text:''
                    },
                    subtitle:{
                        text:''
                    },
                    plotOptions: {
                        column: {
                            stacking: 'overlap',
                            pointWidth: 30
                        },
                        line: {
                            marker: {
                                states: {
                                    hover: {
                                        enabled: false
                                    },
                                    select: {
                                        enabled: false
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: false,
                                crop: false,
                                overflow: 'none',
                                align: 'left',
                                verticalAlign: 'middle',
                                formatter: function() {
                                        //console.log(this.point.label);
                                        return `Margin: ${Intl.NumberFormat('en-au', {
                                            style: 'currency',
                                            currency: 'AUD'
                                        }).format(this.point.label)}`;
                                }
                            },
                            enableMouseTracking: false
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return `<p><strong>Date:</strong> ${moment(this.x).format("DD-MM-YYYY")}</p>` +
                                `<br/>` +
                                `<p><strong>Value:</strong> ${Intl.NumberFormat('en-au', {
                                    style: 'currency',
                                    currency: 'AUD'
                                }).format(this.y)}</p>` +
                                `<br/>` +
                                `<p><strong>Contact:</strong> ${this.point.contact_name}</p>`
                        }
                    },

                    xAxis: {
                        type: 'datetime',
                        min: min.getTime(),
                        max: max.getTime(),
                        endOnTick: false,
                        maxPadding: 0.75
                    },
                    yAxis: {
                        title:{
                            text:''
                        },
                        plotLines: [{
                            value: 0,
                            color: '#ccd6eb',
                            dashStyle: 'shortdash',
                            width: 2,
                        }],
                        tickPositioner: function () {

                            var maxDeviation = Math.ceil(Math.ceil(Math.max(Math.abs(this.dataMax), Math.abs(this.dataMin)))/100)*100;
                            var halfMaxDeviation = Math.ceil(maxDeviation / 2);

                            return [-maxDeviation, -halfMaxDeviation, 0, halfMaxDeviation, maxDeviation];
                        },
                        labels: {
                            formatter:  function () {
                                let value = Intl.NumberFormat('en-au', {
                                    style: 'currency',
                                    currency: 'AUD'
                                }).format(this.value);
                                return value
                            }
                        },
                        endOnTick: false
                        
                    },
                    series: data
                };
            if(props.job.completedAt)
            {
                options['xAxis']['plotLines'] = [{
                    value: new Date(props.job.completedAt).getTime(),
                    color: 'green',
                    dashStyle: 'shortdash',
                    width: 3,
                    label: {
                        text: 'Completed',
                        verticalAlign: 'bottom',
                        textAlign: 'right',
                        y: -10
                    }

                }];
            }
            setOptions(options);
            setIsLoading(false);
        }
    }, [props.isJobUpdated]);
    return (
        <div>
            {!isLoading ?
                <div>
                     <Typography variant="h6">
                                    Timeline
                                </Typography>
                    <HighchartsReact highcharts={Highcharts} options={options} immutable={true}/>
                </div>
                : null }
        </div>
    )
}