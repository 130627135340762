import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import API from "../../api";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { green } from '@material-ui/core/colors';
import CircularProgress from "@material-ui/core/CircularProgress";
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import ShareButton from "./ShareButton";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    menuDropDown:{
        marginTop:  theme.spacing(1)
    },
    margin:{
        marginTop:  theme.spacing(1)
    }
}));

export default function StatusBtn(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [job, setJob] = React.useState(null);
    const [btnLoading, setBtnLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    useEffect(() => {
        // Update the document title using the browser API
        setJob(props.job);
    }, [props.isJobUpdated]);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    function handleCompleteBtnClick(){
        changeStatus(1);
    }
    function handleActiveBtnClick(){
        changeStatus(0);
    }
    function handleUnmergeBtnClick(){
        unmergeJob()
    }
    async function changeStatus(status) {
        setBtnLoading(true);
        let url = `job-detail/${job.id}/`;
        job["status"] = status;
        await API.patch(url, job)
            .then(function (response) {
                props.handleJobUpdate(response.data);
                setBtnLoading(false);
                if(props.isList) {
                    props.rowData[13].props.updateValue(job["status"]);
                }

            })
            .catch(function (error) {
                console.log(error);
                setBtnLoading(false);
            });
    }

    async function unmergeJob() {
        setBtnLoading(true);
        props.handleLoading(true);
        await API.post('/unmerge-job/', {
            jobId: job.id
        })
            .then(function (response) {
                setBtnLoading(false);
                enqueueSnackbar(`Job Unmerged`, {
                    variant: 'success'
                });
                props.closePanel();
            })
            .catch(function (error) {
                console.log(error);
                props.handleLoading(false);
                setBtnLoading(false);
            });
    }

    return (
        <ButtonGroup size="small" aria-label="small outlined button group">
            {job ?
                job.status === 0?
                    <div className={classes.wrapper}>
                        <Button variant="contained" color="primary" onClick={handleCompleteBtnClick} className={classes.margin}>
                            Mark as Completed
                        </Button>
                        {btnLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                    :null
                : null
            }
            {job ?
                <React.Fragment>
                    {props.isList ?
                        <ShareButton job={job}/>: null}
                    {
                        job.status === 1 || job.linkedJobs.length > 0 ?
                            <React.Fragment>
                                <Button aria-controls="simple-menu" size="large" aria-haspopup="true"
                                        onClick={handleClick} className={classes.menuDropDown}>
                                    <MoreVertSharpIcon/>
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {job.status === 1 ?
                                        <MenuItem onClick={handleClose}>
                                            <Link onClick={handleActiveBtnClick} className={classes.link}
                                                  color="inherit"
                                                  underline="none">
                                                Reopen Job
                                            </Link>
                                        </MenuItem>
                                        : null
                                    }
                                    {job.linkedJobs.length > 0 ?
                                        <MenuItem onClick={handleClose}>
                                            <Link onClick={handleUnmergeBtnClick} className={classes.link}
                                                  color="inherit"
                                                  underline="none">
                                                Unmerge Job
                                            </Link>
                                        </MenuItem>
                                        : null

                                    }
                                </Menu>
                            </React.Fragment> : null
                    }
                </React.Fragment>: null
            }
        </ButtonGroup>
    );
}