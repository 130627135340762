import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import ReconcileList from "../components/reconcile/ReconcileList"
import { loadCSS } from 'fg-loadcss';
import DocumentTitle from "react-document-title";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    listContainer: {
        marginTop: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    }
}));

export default function ReconcileView() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [invoiceCount, setInvoiceCount] = React.useState(0);
    const [purchaseCount, setPurchaseCount] = React.useState(0);
    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);
    React.useEffect(() => {
        document.title = "Reconcile Jobs";
    },[]);
    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function handleCountChange(invoiceType, count){
        if(invoiceType === "ACCREC"){
            setInvoiceCount(count);
        }
        else {
            setPurchaseCount(count);
        }
    }
    return (
        <DocumentTitle title="Reconcile | JobRec">
            <Container maxWidth="lg" className={classes.container}>

                <div className={classes.root}>

                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label={invoiceCount > 0 ? `Invoices (${invoiceCount})`: `Invoices`} {...a11yProps(0)} />
                        <Tab label={purchaseCount > 0 ? `Purchases (${purchaseCount})`: `Purchases`} {...a11yProps(1)} />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <ReconcileList invoiceType={"ACCREC"} handleCountChange={handleCountChange} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ReconcileList invoiceType={"ACCPAY"} handleCountChange={handleCountChange} />
                    </TabPanel>
                </div>


            </Container>
        </DocumentTitle>
    );
}