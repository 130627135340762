import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InvoiceLineCard from "../InvoiceLineCard";
import RightReconcileCard from "../RightReconcileCard";
import ReconcileActions from "./ReconcileActions";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import API from "../api";
import {async} from "fast-glob";
import NotificationBar from "../NotificationBar";
import { useSnackbar } from 'notistack';
import CircularProgress from "@material-ui/core/CircularProgress";
import {green} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    hideClass: {
        display: "none",
    },
    progressWrapper:{
        margin: theme.spacing(1),
        position: 'relative',
        minHeight: 250
    },
    lineProgress:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

export default function ReconcileRow(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [line, setLine] = React.useState(props.line);
    const [selectedMatch, setSelectedMatch] = React.useState(null);
    const [hideClass, setHideClass] = React.useState(null);
    const [showSnackBar, setShowSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState(null);
    const [discussMessage, setDiscussMessage] = React.useState(null);
    const [jobReconciledId, setJobReconciledId] = React.useState(null);
    const [jobUnReconciledId, setJobUnReconciledId] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [lineLoaded, setLineLoaded] = React.useState(true);
    const [note, setNote] = React.useState(null);
    // add multiple actions to one snackbar
    const UndoReconcileAction = (key) => (
        <Fragment>
            <Button onClick={() => {undoReconcile(key)}}>
                {'Undo'}
            </Button>
        </Fragment>
    );
    const UndoDeleteAction = (key) => (
        <Fragment>
            <Button onClick={() => {undoDeleteLine(key)}}>
                {'Undo'}
            </Button>
        </Fragment>
    );
    const RedoLineReconcileAction = (key) => (
        <Fragment>
            <Button onClick={() => {redoReconcileJob(key)}}>
                {'Redo'}
            </Button>
        </Fragment>
    );
    React.useEffect(() => {
        if(typeof line.matchingJob.uid !== "undefined"){

            setSelectedMatch(line.matchingJob.id);
        }
        else {
            setSelectedMatch(null);
        }
        if(props.line.invoiceLineMessages.length > 0){
            setDiscussMessage(props.line.invoiceLineMessages[0]);
        }
        if(props.line.invoiceLineNotes.length > 0){
            setNote(props.line.invoiceLineNotes[0]);
        }
    }, []);
    React.useEffect(() => {
        if(jobReconciledId) {
            enqueueSnackbar(`Line ${line.invoice.reference} reconciled`, {
                action: UndoReconcileAction,
                variant: 'success'
            });
        }
    },[jobReconciledId]);
    React.useEffect(() => {
        if(jobUnReconciledId) {
            enqueueSnackbar(`Line ${line.invoice.reference} reconcile undo success`, {
                action: RedoLineReconcileAction,
                variant: 'success'
            });
        }
    },[jobUnReconciledId]);
    function handleMatchSelect(job) {
        setSelectedMatch(job.id);
    }
    function handleOkClick() {
        handleReconcileJob(selectedMatch);
    }
    function handleUndoClick() {
        undoReconcileJob(selectedMatch);
    }
    function hideRow() {
        setHideClass(classes.hideClass);
        // setShowSnackBar(true);
        // setSnackBarMessage(`Line ${line.invoice.reference} reconciled`);
        props.handleRowHide();


    }
    function showRow() {
        props.handleRowShow();
        setHideClass(null);
        setShowSnackBar(false);
        setSnackBarMessage(null);
    }
    function handleUndo(){
        showRow();
    }
    function handleNoteChange(note){
        setNote(note);
    }
    function handleDeleteLine(e){
        deleteLine(line.id);
    }

    // API Funtions
    async function handleReconcileJob(jobId){
        setIsLoading(true);
        await API.post(`reconcile-job/`, {
            jobId: jobId,
            invoicelineId: props.line.id,
        })
            .then(function (response) {
                //console.log(response);
                hideRow();
                setJobReconciledId(jobId);
                setIsLoading(false);
            })
            .catch(function (error) {
                //console.log(error);
                setIsLoading(false);
            });
    }
    async function undoReconcile(key){
        await API.post(`undo-reconcile-job/`, {
            jobId: jobReconciledId,
            invoicelineId: props.line.id,
        })
            .then(function (response) {
                //console.log(response);
                showRow();
                closeSnackbar(key);
                setJobReconciledId(null);
                enqueueSnackbar(`Undo success`, {variant: 'success'});
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function undoReconcileJob(jobId){
        setIsLoading(true);
        setLineLoaded(false);
        await API.post(`undo-reconcile-job/`, {
            jobId: jobId,
            invoicelineId: props.line.id,
        })
            .then(function (response) {
                //console.log(response);
                setLine(response.data.line);
                setJobUnReconciledId(jobId);
                setIsLoading(false);
                setLineLoaded(true);

            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }
    async function redoReconcileJob(key){
        setLineLoaded(false);
        await API.post(`reconcile-job/`, {
            jobId: jobUnReconciledId,
            invoicelineId: props.line.id,
        })
            .then(function (response) {
                //console.log(response);
                closeSnackbar(key);
                setLine(response.data.line);
                setJobUnReconciledId(null);
                setLineLoaded(true);
                enqueueSnackbar(`Redo success`, {variant: 'success'});

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function deleteLine(lineId){
        await API.patch(`invoice-line-list/${lineId}/`, {
            hide: true,
        })
            .then(function (response) {
                //console.log(response);
                hideRow();
                enqueueSnackbar(`Line ${line.invoice.reference} deleted`, {action: UndoDeleteAction, variant: 'success'});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function undoDeleteLine(key){
        await API.patch(`invoice-line-list/${line.id}/`, {
            hide: false,
        })
            .then(function (response) {
                //console.log(response);
                showRow();
                closeSnackbar(key);
                enqueueSnackbar(`Undo Success`, {variant: 'success'});
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>
        {lineLoaded ?(
                <Grid container spacing={5} alignItems="center" key={line.id} className={hideClass}>
                    <Grid item xs={5}>
                        <InvoiceLineCard line={line} note={note} enqueueSnackbar={enqueueSnackbar}
                                         handleNoteChange={handleNoteChange} handleDeleteLine={handleDeleteLine}/>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                    >
                        <ReconcileActions line={line} selectedMatch={selectedMatch} onOkClick={handleOkClick}
                                          onUndoClick={handleUndoClick} isLoading={isLoading}/>
                    </Grid>
                    <Grid item xs={5}>
                        <RightReconcileCard line={line} discussMessage={discussMessage}
                                            onMatchSelect={handleMatchSelect} hideRow={hideRow}
                                            handleReconcileJob={handleReconcileJob}
                                            enqueueSnackbar={enqueueSnackbar}/>
                    </Grid>
                </Grid>
                )
                : (
                    <div className={classes.progressWrapper}>
                        <CircularProgress size={24} className={classes.lineProgress} />
                    </div>
            )
        }
        </div>
    );
}