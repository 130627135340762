import React, {Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BreadCrumb from "./BreadCrumb";
import {Link, NavLink} from "react-router-dom";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {Permissions, Role, hasPermission, isRole} from "./UserAccess";
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,

    },
    appName: {
        marginRight: theme.spacing(4),
    },
    menuButton: {
        // marginRight: theme.spacing(4),
        paddingTop: "17px",
        paddingBottom: "17px",
        textTransform: "none",
        fontWeight: 700,
    },
    title: {
        flexGrow: 1,
    },
    link: {
       textDecoration: 'none',
       color: '#ffffff',
    },
    grow: {
        flexGrow: 1,
    },
    activeLink: {
        // backgroundColor: "rgba(0,10,30,0.5)",
        borderBottom: "3px solid rgba(255,255,255,0.75)"

    },
    menuIcon: {
        marginRight: theme.spacing(2),
    }
}));

function NavBar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h5" color="inherit" className={classes.appName}>
                        JobRec
                    </Typography>
                    <NavLink exact to={"/"} className={classes.link} activeStyle={{borderBottom: "3px solid rgba(255,255,255,0.75)"}}>
                        <Button size="large" color="inherit" className={classes.menuButton}>Dashboard</Button>
                    </NavLink>
                    {hasPermission('Reconcile') ?
                        <NavLink exact to={"/reconcile"} className={classes.link}
                                 activeStyle={{borderBottom: "3px solid rgba(255,255,255,0.75)"}}>
                            <Button size="large" color="inherit" className={classes.menuButton}>Reconcile</Button>
                        </NavLink>: null
                    }
                    {hasPermission('Jobs') ?
                        <NavLink exact to={"/jobs"} className={classes.link}
                                 activeStyle={{borderBottom: "3px solid rgba(255,255,255,0.75)"}}>
                            <Button size="large" color="inherit" className={classes.menuButton}>Jobs</Button>
                        </NavLink> : null
                    }
                    {
                        hasPermission('Report') ?
                        <NavLink exact to={"/report"} className={classes.link}
                                 activeStyle={{borderBottom: "3px solid rgba(255,255,255,0.75)"}}>
                            <Button size="large" color="inherit" className={classes.menuButton}>Report</Button>
                        </NavLink> : null
                    }
                    <div className={classes.grow} />
                    {hasPermission("Admin Panel")?
                    <Button color="inherit" href="https://jobrecapp.com/admin" target="_blank">Admin</Button>
                        : null}
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            className={classes.grow}
                        >
                            <AccountCircle />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem component={Link} to={"/change-password"}>
                                <VpnKeyIcon className={classes.menuIcon} />
                                Change Password
                            </MenuItem>
                            <MenuItem onClick={props.handleAppLogout}>
                                <ExitToAppIcon className={classes.menuIcon} />
                                Logout
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <BreadCrumb/>
        </div>
    );
}

export default withRouter(NavBar);