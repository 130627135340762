import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const BASE_URL = process.env.REACT_APP_API_URL;

const LoginAPI = axios.create({
    baseURL: BASE_URL,
});


const API = axios.create({
    baseURL: BASE_URL,
});

function getAccessToken(){
    var access_token = JSON.parse(localStorage.getItem('access_token'));
    //console.log(access_token);
    return access_token;
}
function getRefreshToken(){
    var refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
    //console.log(refresh_token);
    return refresh_token;
}

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => API.post('token/refresh/',{refresh: getRefreshToken()}).then(tokenRefreshResponse => {
    //console.log(tokenRefreshResponse);
    localStorage.setItem('access_token', JSON.stringify(tokenRefreshResponse.data.access));
    // localStorage.setItem('userAccess', JSON.stringify(tokenRefreshResponse.data.userAccess));
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.access;
    return Promise.resolve();
}).catch(function (error){
    window.location.replace('/login');
});

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(API, refreshAuthLogic);

API.interceptors.request.use(request => {
    if(getAccessToken()){
        request.headers['Authorization'] = 'Bearer ' + getAccessToken();
    }
    return request;
});



export default API;
export {
    LoginAPI
}