import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import ReconcileRow from "./ReconcileRow";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function FileList(props) {
    const classes = useStyles();
    const [files, setFiles] = React.useState(props.files);
    const [isFilesLoading, setIsFilesLoading] = React.useState(props.isFilesLoading);
    React.useEffect(() => {
        setFiles(props.files);
    }, [isFilesLoading]);
    return (
        <div className={classes.root}>
            {files.length > 0 ?
            <List component="nav" aria-label="main mailbox folders">
                {files.map((file, i) =>
                    <ListItem key={file.id} button>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary={file.fileName} />
                    </ListItem>
                )}

            </List>
            : null }
        </div>
    );
}