import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Typography} from "@material-ui/core";
import API, {LoginAPI} from "../../api";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    table : {
        minWidth: 650
    },
    cellSelect: {
        cursor: 'pointer'
    }
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

export default function JobMergePanel(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { onClose, open, data } = props;
    const backgroundColor = '#bbdefb';
    const [mergeIds, setMergeIds] = React.useState([]);
    const [nameId, setNameId] = React.useState(null);
    const [ownerId, setOwnerId] = React.useState(null);
    const [clientId, setClientId] = React.useState(null);
    const [sourceId, setSourceId] = React.useState(null);
    const [nameValue, setNameValue] = React.useState(null);
    const [ownerValue, setOwnerValue] = React.useState(null);
    const [clientValue, setClientValue] = React.useState(null);
    const [sourceValue, setSourceValue] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const handleClose = () => {
        onClose();
    };
    const handleClick = (colName, id, value) =>{
        if(colName === 'name') {
            setNameId(id);
            setNameValue(value);
        }
        if(colName === 'owner') {
            setOwnerId(id);
            setOwnerValue(value);
        }
        if(colName === 'client') {
            setClientId(id);
            setClientValue(value);
        }
        if(colName === 'source') {
            setSourceId(id);
            setSourceValue(value);
        }
    };
    useEffect(() => {
        // Update the document title using the browser API
        if(data) {
            const first = props.data[0];
            const jobIds = props.data.map(job => {return job.id});
            setMergeIds(jobIds);
            setNameId(first.id);
            setOwnerId(first.id);
            setClientId(first.id);
            setSourceId(first.id);

            setNameValue(first.name);
            setOwnerValue(first.ownerName);
            setClientValue(first.client);
            setSourceValue(first.source);
            const total = data.reduce(function (acc, row) {
                return acc + parseFloat(row.value);
            }, 0);
            setValue(total.toFixed(2));
        }

    }, [props.data]);

    const handleMergeClick = async () => {
        setLoading(true);
        await API.post('/merge-jobs/', {
            mergeIds: mergeIds,
            name: nameValue,
            value: value,
            owner: ownerValue,
            client: clientValue,
            source: sourceValue

        })
            .then(function (response) {
                onClose();
                setLoading(false);
                enqueueSnackbar(`Jobs Merged`, {
                    variant: 'success'
                });
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    };
    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth='xl' aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Merge Jobs</DialogTitle>
            <DialogContent>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Job Name</TableCell>
                            <TableCell>Job Value</TableCell>
                            <TableCell>Owner</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell align="right">Source</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data?
                            props.data.map(row => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row" onClick={() => handleClick('name', row.id, row.name)} className={classes.cellSelect} style={nameId === row.id ? {background: backgroundColor} : {background: "none"}}>
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.value}</TableCell>
                                <TableCell onClick={() => handleClick('owner', row.id, row.ownerName)} className={classes.cellSelect} style={ownerId === row.id ? {background: backgroundColor} : {background: "none"}}>{row.ownerName}</TableCell>
                                <TableCell onClick={() => handleClick('client', row.id, row.client)} className={classes.cellSelect} style={clientId === row.id ? {background: backgroundColor} : {background: "none"}}>{row.client}</TableCell>
                                <TableCell align="right" onClick={() => handleClick('source', row.id, row.source)} className={classes.cellSelect} style={sourceId === row.id ? {background: backgroundColor} : {background: "none"}}>{row.source}</TableCell>
                            </TableRow>
                        )) : null}
                        <TableRow key="merged-label">
                            <TableCell id="merge-label" colSpan={5}>
                            </TableCell>
                        </TableRow>
                        <TableRow key="merged">
                            <TableCell>
                                <TextField id="name" multiline={true} fullWidth={true} label={false} value={nameValue} onChange={event => setNameValue(event.target.value)}/>
                            </TableCell>
                            <TableCell>
                                <TextField id="job-value" type={'number'} inputProps={{step: .01}} fullWidth={true} label={false} value={value} onChange={event=> setValue(event.target.value)}/>
                            </TableCell>
                            <TableCell>
                                <TextField id="owner-name"  fullWidth={true} label={false} value={ownerValue} onChange={event => setOwnerValue(event.target.value)}/>
                            </TableCell>
                            <TableCell>
                                <TextField id="client"  fullWidth={true} label={false} value={clientValue} onChange={event => setClientValue(event.target.value)}/>
                            </TableCell>
                            <TableCell align="right">
                                <TextField id="source" inputProps={{style: { textAlign: "right" }}} fullWidth={true} label={false} value={sourceValue} onChange={event => setSourceValue(event.target.value)}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleMergeClick} color="primary" autoFocus disabled={loading}>
                    Merge
                </Button>
            </DialogActions>
        </Dialog>
    );
}