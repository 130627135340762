import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';
import API from "../api";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

export default function FileUpload(props) {
    const classes = useStyles();
    const [percent, setPercent] = React.useState(0);
    const dropzoneRef = React.createRef()
    const onDrop = useCallback(acceptedFiles => {
        let data = new FormData();
        data.append('file', acceptedFiles[0], acceptedFiles[0].name);
        data.append('invoiceLine', props.line.id);
        upload(data);
    }, []);
    const config = {
        onUploadProgress: progressEvent => {
            var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
            if (percent >= 100) {
                setPercent(100);
            } else {
                setPercent(percent);
            }
        }
    };
    async function upload(data) {
        await API.post("invoice-line-files/", data, config)
            .then(function (response) {
                //console.log(response);
                props.handleNewFile(response.data);
                setPercent(0)
;            })
            .catch(function (error) {
                console.log("error", error);
            })
    }
    const {getRootProps, getInputProps, open, isDragActive} = useDropzone({onDrop: onDrop, noKeyboard:true})

    return (
        <div>
            <div {...getRootProps()}>
                <input {...getInputProps()} />

            </div>
            <Button variant="contained" color="default" className={classes.button} onClick={open}>
                Upload
                <CloudUploadIcon className={classes.rightIcon} />
            </Button>
            {percent > 0 ? <LinearProgress variant="determinate" value={percent} />: null}
        </div>
    )
}