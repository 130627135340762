import React, {useCallback, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import API from '../components/api'
import {Redirect} from "react-router-dom";
import DocumentTitle from "react-document-title";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import clsx from "clsx";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    errorMsg: {
        textAlign: 'center',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const PasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Required'),
    newPassword1: Yup.string().required('Required'),
    newPassword2: Yup.string().required('Required')
});

export default function ChangePasswordView(props) {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    React.useEffect(() => {
        props.handleNavbar(true);
    }, []);

    async function updatePassword(values, actions) {
        await API.post('rest-auth/password/change/', values)
            .then(function (response) {
                //console.log(response);
                enqueueSnackbar(`Password Changed Successfully`, {variant: 'success'});
                actions.setSubmitting(false);
                props.history.push("/");
                props.handleNavbar(false);
            })
            .catch(function (error) {
                actions.setErrors(error.response.data);
                actions.setSubmitting(false);
            });
    }
    return (
        <DocumentTitle title="Change Password | JobRec">
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <VpnKeyIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Update Password
                    </Typography>

                    <Formik
                        initialValues={{
                            oldPassword: "",
                            newPassword1: "",
                            newPassword2: ""
                        }}
                        validationSchema={PasswordSchema}
                        onSubmit={(values, actions ) => {
                            setTimeout(() => {
                                updatePassword(values, actions);
                            }, 400);
                        }}
                    >
                        {({ errors, touched , handleChange, isSubmitting }) => (
                            <Form className={classes.container} autoComplete="off">
                                {errors.name ? (
                                    <div>{errors}</div>
                                ) : null}
                                <Field
                                    id="oldPassword"
                                    label="Current Password"
                                    name="oldPassword"
                                    className={clsx(classes.textField, classes.dense)}
                                    margin="normal"
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    component={TextField}
                                    variant="outlined"
                                    type="password"
                                    error={errors.oldPassword && touched.oldPassword}
                                    helperText={errors.oldPassword && touched.oldPassword && errors.oldPassword}
                                />
                                <Field
                                    id="newPassword1"
                                    label="New Password"
                                    name="newPassword1"
                                    className={clsx(classes.textField, classes.dense)}
                                    margin="normal"
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    component={TextField}
                                    variant="outlined"
                                    type="password"
                                    error={errors.newPassword1 && touched.newPassword1}
                                    helperText={errors.newPassword1 && touched.newPassword1 && errors.newPassword1}
                                />
                                <Field
                                    id="newPassword2"
                                    label="New Password Confirm"
                                    name="newPassword2"
                                    className={clsx(classes.textField, classes.dense)}
                                    margin="normal"
                                    onChange={handleChange}
                                    required
                                    fullWidth
                                    component={TextField}
                                    variant="outlined"
                                    type="password"
                                    error={errors.newPassword2 && touched.newPassword2}
                                    helperText={errors.newPassword2 && touched.newPassword2 && errors.newPassword2}
                                />
                                <Button
                                    type="submit"
                                    color="primary"
                                    fullWidth
                                    variant="contained"
                                    className={classes.button}
                                    disabled={isSubmitting}>
                                    Change Password
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Container>
        </DocumentTitle>
    );
}