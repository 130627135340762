
const hasPermission = (permission) => {
    let userAccess = JSON.parse(localStorage.getItem('userAccess'));
    if(!userAccess){
        userAccess = {
            'role': null,
            'permissions': []
        };
    }
    let Permissions = userAccess.permissions;
    return Permissions.includes(permission);
};

const isRole = (role) => {
    let userAccess = JSON.parse(localStorage.getItem('userAccess'));
    if(!userAccess){
        userAccess = {
            'role': null,
            'permissions': []
        };
    }
    return role === userAccess.role;
};

export {
    hasPermission,
    isRole
}