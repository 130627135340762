import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from '@material-ui/core/colors';
import API from "../../api";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import UndoIcon from '@material-ui/icons/Undo';
import {RLink, NavLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import ExitToAppIcon from "@material-ui/core/SvgIcon/SvgIcon";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    table: {
        minWidth: 650,
        tableLayout: "fixed",
        backgroundColor: "#ffffff",
    },
    descriptionCell: {
       width: "60%",
    },
    totalCell: {
      border: "none"
    },
    button: {
        margin: theme.spacing(1),
      },
      buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    menuIcon: {
        marginRight: theme.spacing(2),
    }
}));

export default function UnlinkButton(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [job, setJob] = React.useState(props.job);
    const [line, setLine] = React.useState(props.line);
    const [isLoading, setIsLoading] = React.useState(false);
    async function undoReconcileJob(lineId){
        setIsLoading(true);
        await API.post(`undo-reconcile-job/`, {
            jobId: job.id,
            invoicelineId: line.id,
        })
            .then(function (response) {
                //console.log(response);
                setIsLoading(false);
                props.handleRefresh();
                setAnchorEl(null);
    
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false);
                setAnchorEl(null);
            });
    }
    return (
        <div className={classes.wrapper}>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertSharpIcon/>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} component={Link} target="_blank" href={line.invoice.xeroUrl} underline="none">
                    <VisibilityIcon className={classes.menuIcon} />
                    View in Xero
                </MenuItem>
                <MenuItem onClick={undoReconcileJob} >
                    <UndoIcon className={classes.menuIcon} />
                     Unlink
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </MenuItem>
            </Menu>
        </div>

    );
}

