import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MergeTypeIcon from '@material-ui/icons/MergeType';
import {makeStyles, withStyles} from "@material-ui/core/styles";

const defaultToolbarStyles = {
    iconButton: {
    },
};
const useStyles = makeStyles(theme => ({
}));

function JobListCustomToolbarSelect(props){
    const classes = useStyles();
    const { handleMergeData } = props;
    const [disabled, setDisabled] = React.useState(true);
    const handleClick = () => {
        const selected_dataindex = props.selectedRows.data.map(selected => {return selected.dataIndex});
        const selected_ids = props.displayData.filter(data => selected_dataindex.includes(data.dataIndex)).map(selected => {return selected.data[0]});
        handleMergeData(selected_ids)
    };

    React.useEffect(() => {
        if(props.selectedRows.data.length > 1){
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }

    },[props.selectedRows]);

    return (
        <React.Fragment>
            <Tooltip title={"Merge Jobs"}>
                <IconButton className={classes.iconButton} onClick={handleClick} disabled={disabled}>
                    <MergeTypeIcon className={classes.deleteIcon} />
                </IconButton>
            </Tooltip>
        </React.Fragment>
);

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(JobListCustomToolbarSelect);