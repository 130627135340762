import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
    },
    pos: {
        marginBottom: 12,
    },
    link: {
        textDecoration: 'none'
    }
});

function DashboardWidget(props) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                   {props.card_title}
                </Typography>
            </CardContent>
            <CardActions>
                <Link to={props.link_to} className={classes.link}>
                    <Button size="medium" variant="contained" color="primary">{props.button_name}</Button>
                </Link>
            </CardActions>
        </Card>
    );
}

export default DashboardWidget;