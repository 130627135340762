import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import DashboardWidget from "../components/DashboardWidget";
import {makeStyles} from "@material-ui/core";
import DocumentTitle from "react-document-title";
import {hasPermission} from "../components/UserAccess";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,

    },
    menuButton: {
        marginRight: theme.spacing(4),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        background: '#0078c8',
    },
    container: {
        marginTop: 30
    }
}));


function DashboardView() {
    const classes = useStyles();
    return (
        <DocumentTitle title="Dashboard | JobRec">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg" className={classes.container}>

                    <Grid container spacing={3}>
                        {hasPermission('Reconcile') ?
                        <Grid item xs={6}>
                            <DashboardWidget card_title={'Reconcile Jobs'} button_name={'Reconcile Jobs'} link_to={"/reconcile"} />
                        </Grid>:null}
                        {hasPermission('Jobs') ?
                        <Grid item xs={6}>
                            <DashboardWidget card_title={'View Jobs'} button_name={'View Jobs'} link_to={"/jobs"} />
                        </Grid>:null}
                        {hasPermission('Report') ?
                        <Grid item xs={6}>
                            <DashboardWidget card_title={'Reports'} button_name={'View Reports'} link_to={"/report"} />
                        </Grid>:null}
                    </Grid>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}

export default DashboardView;