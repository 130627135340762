import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import moment from 'moment/min/moment-with-locales';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {green} from "@material-ui/core/colors";
import API from "../components/api";
import DocumentTitle from "react-document-title";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
        padding: theme.spacing(2)
    },
    table: {
        maxWidth: 300,
    },
    dateFieldWrapper: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        float: "left",
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    grid: {
        marginTop: theme.spacing(2),
        alignItems: "center",
    }
}));

const fileDownload = require('js-file-download');

export default function ReportView() {
    // The first commit of Material-UI
    const classes = useStyles();
    const [startDate, setStartDate] = React.useState(moment().subtract(30, 'day'));
    const [endDate, setEndDate] = React.useState(moment);
    const [btnLoading, setBtnLoading] = React.useState(false);
    
    function handleStartDateChange(date) {
        setStartDate(date);
        setEndDate(moment(date).add(30,'day'))
    }
    function handleEndDateChange(date) {
        setEndDate(date);
    }
    function handleClick() {
        downloadCsv();
    }
    async function downloadCsv() {
        setBtnLoading(true);
        await API.post("report-csv/",{
            startDate: startDate,
            endDate: endDate
        })
            .then(function (response) {
                //console.log(response);
                fileDownload(response.data, `report-${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}.csv`);
                setBtnLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setBtnLoading(false);
            });
    }
    return (
        <DocumentTitle title="Report | JobRec">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg" className={classes.container}>
                    <Paper className={classes.root}>
                        <Typography variant="h4" component="h2">
                            Generate Report
                        </Typography>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Grid container spacing={5} className={classes.grid}>
                                <Grid item xs={3}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="DD/MM/YYYY"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Start Date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="DD/MM/YYYY"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="End Date"
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.wrapper}>
                                        <Button variant="contained" size="large" color="primary" onClick={handleClick} className={classes.margin} disabled={btnLoading}>
                                            Download
                                        </Button>
                                        {btnLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}