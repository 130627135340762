import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ShareIcon from '@material-ui/icons/Share';
import TextField from "@material-ui/core/TextField";
import {APP_URL} from "../../Utils";

const useStyles = makeStyles(theme => ({
    menuDropDown:{
        marginTop:  theme.spacing(1),
    },
    button:{
        height: '100%'
    }
}));
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        '&:hover':{
            backgroundColor: 'transparent'
        }
    },
}))(MenuItem);

export default function ShareButton(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [job, setJob] = React.useState(props.job);
    const [btnText, setBtnText] = React.useState("Copy");
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setBtnText("Copy");
        setAnchorEl(null);
    };
    const handleMenuClick = event => {
        event.preventDefault();
    };
    const handleCopyUrl = event => {
        let copyText = document.getElementById(`copy-url-text-${job.id}`);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        setBtnText("Copied");
    };

    return (
        <React.Fragment>
            <Button
                aria-controls="customized-menu"
                size="large"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.menuDropDown}
            >
                <ShareIcon/>
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem
                    onClick={handleMenuClick}
                    disableRipple={true}
                >
                    <TextField
                        id={`copy-url-text-${job.id}`}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        value={`${APP_URL}/jobs/${job.id}`}
                    />
                    <Button variant="outlined" size="medium" className={classes.button} onClick={handleCopyUrl}>
                        {btnText}
                    </Button>
                </StyledMenuItem>
            </StyledMenu>
        </React.Fragment>
    );
}