import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from '@material-ui/core/colors';
import Moment from 'react-moment';
import API from "../api";
import UnlinkButton from "./components/UnlinkButton";
import CurrencyFormat from "../_helpers/CurrencyFormat";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
    },
    table: {
        minWidth: 650,
        padding: theme.spacing(3, 2),
        tableLayout: "fixed",
        backgroundColor: "#ffffff",
    },
    descriptionCell: {
       width: "60%",
    },
    totalCell: {
      border: "none"
    },
    button: {
        margin: theme.spacing(1),
      },
      buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    tableCell:{
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    }
}));



const Quantity = (props) => {
    let line = props.line;
    return  (parseFloat(line.quantity)).toFixed(0);
};
const UnitAmount = (props) => {
    let line = props.line;
    let amount =  (parseFloat(line.unitAmount)).toFixed(2);
    return <CurrencyFormat value={amount}/>;
};
const LineAmount = (props) => {
    let line = props.line;
    let amount =  (parseFloat(line.lineAmount)).toFixed(2);
    return <CurrencyFormat value={amount}/>;
};
const Total = (props) => {
    let lines = props.lines;
    let total = lines.reduce(function (total, line) {
        return total + parseFloat(line.lineAmount);
    }, 0).toFixed(2);
    return <CurrencyFormat value={total}/>
};
export default function JobLines(props) {
    const classes = useStyles();
    const [job, setJob] = React.useState(props.job);
    const [lines, setLines] = React.useState(props.lines);
    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell align="left" className={classes.tableCell} padding="none">Date</TableCell>
                    <TableCell align="left" className={classes.tableCell} padding="none">Invoice Number</TableCell>
                    <TableCell align="left" className={classes.tableCell} padding="none">Contact</TableCell>
                    <TableCell align="left" className={classes.tableCell} padding="none">Description</TableCell>
                    <TableCell align="right" className={classes.tableCell} padding="none">Amount</TableCell>
                    <TableCell align="right" className={classes.tableCell} padding="none"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {lines.map(line => (
                    <TableRow key={line.id}>

                        <TableCell align="left" className={classes.tableCell} padding="none">
                            <Moment format="DD/MM/YYYY">
                                {line.invoice.invoiceDate}
                            </Moment>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell} padding="none">{line.invoice.number}</TableCell>
                        <TableCell align="left" className={classes.tableCell} padding="none">{line.invoice.contactName}</TableCell>
                        <TableCell align="left" className={classes.tableCell} padding="none">
                            <Typography variant="inherit" noWrap={true} display="block" style={{width:"100%"}}>
                                {line.description}
                            </Typography>
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell} padding="none"><LineAmount line={line}/></TableCell>
                        <TableCell align="right" className={classes.tableCell} padding="none">
                            <UnlinkButton job={job} line={line} handleRefresh={props.handleRefresh}/>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell rowSpan={4} className={classes.totalCell} padding="none"/>
                    <TableCell align="right" colSpan={3} className={classes.totalCell} padding="none">
                        <Typography variant="h6">
                            Total
                        </Typography>
                    </TableCell>
                    <TableCell align="right" className={classes.totalCell} padding="none">
                        <Typography variant="h6">
                            <Total lines={lines}/>
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

