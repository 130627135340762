import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core";
import JobList from "../components/jobs/JobList";
import DocumentTitle from "react-document-title";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,

    },
    menuButton: {
        marginRight: theme.spacing(4),
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        background: '#0078c8',
    },
    container: {
        marginTop: 30
    }
}));


function JobsView() {
    const classes = useStyles();
    return (
        <DocumentTitle title="Jobs | JobRec">
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="xl" className={classes.container}>
                    <JobList/>
                </Container>
            </React.Fragment>
        </DocumentTitle>
    );
}

export default JobsView;