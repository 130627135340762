import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    inputRoot: {
        '& > *': {
            width: 85,
            fontSize: 12,
        },
    }

}));

export default function GoToPage(props) {
    const classes = useStyles();
    const [page, setPage] = useState("");
    const handlePageSubmit = () => {
      let offset = (page-1) * 10;
      props.handlePageClick(offset, page)
    };
    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handlePageSubmit}>
            <Input type="number" placeholder="Go to page" size="small" value={page} className={classes.inputRoot} inputProps={{ 'aria-label': 'description' }} onChange={e => setPage(parseInt(e.target.value))}/>
            <IconButton type="submit" aria-label="go" className={classes.iconButton}>
                <ArrowRightAltIcon fontSize="small" />
            </IconButton>
        </form>
    );
}