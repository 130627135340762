import React, { useState, useEffect } from 'react';
import PropTypes, {func} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReconcileRow from "./ReconcileRow";
import API from "../api";
import Pagination from "material-ui-flat-pagination";
import CircularProgress from '@material-ui/core/CircularProgress';
import TabSearch from "../TabSearch";
import TextField from '@material-ui/core/TextField';
import GoToPage from "./goToPage";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        marginTop: 30
    },
    listContainer: {
        marginTop: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    progressDiv: {
        textAlign: "center",
    },
    paginationDiv:{
        textAlign: "center"
    }
}));

export default function ReconcileView(props) {
    const classes = useStyles();
    const [invoiceData, setInvoiceData] = React.useState([]);
    const [totalInvoicesCount, setTotalInvoicesCount] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentOffset, setCurrentOffset] = React.useState(0);
    const [changeLine, setChangeLine] = React.useState(null);
    const [changeSelection, setChangeSelection] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [search_q, setSearch_q] = React.useState('');
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNo, setPageNo] = React.useState(1);
    const [reconcileSize, setReconcileSize] = React.useState(0);
    const [switchState, setSwitchState] = React.useState({
        switch: true
    });
    const handleSwitchChange = (event) => {
        setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
    };
    useEffect(() => {
        // Update the document title using the browser API
        fetchData(pageNo);

    }, [search_q, switchState.switch, pageNo]);

    useEffect(() => {
        props.handleCountChange(props.invoiceType, totalInvoicesCount);
    },[totalInvoicesCount]);

    async function fetchData(page){
        setIsLoading(true);
        //console.log("Fetching Data");
        let url = `invoice-lines/?invoice_type=${props.invoiceType}&page_size=${pageSize}&page=${page}`;
        if (search_q !== ''){
            url += `&search_q=${search_q}`
        }
        if (!switchState.switch){
            url += `&show_reconciled=False`
        }
        await API.get(url)
            .then(function (response) {
                //console.log(response);
                setInvoiceData(response.data.results);
                //console.log("length", response.data.results.length);
                setReconcileSize(response.data.results.length);
                setTotalInvoicesCount(response.data.count);
                setIsLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false);
            });
    }

    function handlePageClick(offset, page) {
        setCurrentPage(page);
        setCurrentOffset(offset);
        setPageNo(page);
    }
    function handlePageSelect(page) {
        setPageNo(page);
    }
    function handleSearchQChange(search_q) {
        setPageNo(1);
        setSearch_q(search_q);

    }
    function handleRowHide(){
        setTotalInvoicesCount(totalInvoicesCount - 1);
        let new_reconcileSize = reconcileSize - 1;
        if (new_reconcileSize === 0){
            if (pageNo > 1){
                setPageNo(pageNo - 1);
            }
            else {
                fetchData(pageNo);
            }
        }
        setReconcileSize(new_reconcileSize);
    }
    function handleRowShow(){
        setTotalInvoicesCount(totalInvoicesCount + 1);
        setReconcileSize(reconcileSize + 1)
    }
    return (
        <div>
            <TabSearch search_q={search_q} handleSearchQChange={handleSearchQChange} switchState={switchState} handleSwitchChange={handleSwitchChange}/>
            {!isLoading ?
                <div className={classes.listContainer}>
                    {invoiceData.map((line, i) =>
                        <ReconcileRow key={line.id} line={line} handleRowHide={handleRowHide} handleRowShow={handleRowShow}/>
                    )}
                    {totalInvoicesCount != 0 ?
                        (<div className={classes.paginationDiv}>
                            <Pagination
                            limit={pageSize}
                            offset={currentOffset}
                            total={totalInvoicesCount}
                            onClick={(e, offset, page) => handlePageClick(offset, page)}
                        />
                        <div>
                           <GoToPage handlePageClick={handlePageClick}/>
                        </div>
                        </div>): (<div></div>)
                    }
                </div>:
                <div className={classes.progressDiv}>
                    <CircularProgress className={classes.progress} />
                </div>

            }
        </div>

    );
}