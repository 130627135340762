import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import OptionsDropDown from "./OptionsDropDown";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import MatchTabDetail from "./reconcile/MatchTabDetail"
import FindTab from "./reconcile/FindTab"
import DiscussTab from "./reconcile/DiscussTab"
import API from "./api";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import {loadCSS} from "fg-loadcss";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    card: {
        minWidth: 275,
        backgroundColor: "rgb(248, 248, 248)",
        border: "1px solid rgb(214, 218, 222);",
    },
    cardContentWithTabs: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    tabPanel:{
        height: "100%"

    },
    noMatch:{

    },
    completeMatch: {
        backgroundColor: "#bae58c",
        color: "rgb(45, 115, 0)",
    },
    partialMatch: {
        backgroundColor: "#faffa4",
        color: "#23282c",
    },
    otherMatchesNav: {

        color: "#a1a1a1",
    },
    otherMatchesNavIcon: {
        fontSize: 16,
        marginTop: 3,
    },
    otherMatchesNavButton: {

        backgroundColor: "#FFF",
        "&:hover": {
            backgroundColor: "#FFF"
        }
    },
    otherMatchesNavButtonDiv: {
        textAlign: "right",
        marginTop: 2
    },
    customIndicator: {
        backgroundColor: "#cacaca",
    }
}));

export default function RightReconcileCard(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [line, setLine] = React.useState(props.line);
    const [discussMessage, setDiscussMessage] = React.useState(props.discussMessage);
    const [shownJob, setShownJob] = React.useState(null);
    const [otherMatches, setOtherMatches] = React.useState([]);
    const [otherMatchesCount, setOtherMatchesCount] = React.useState(0);
    const [showOtherMatchNav, setShowOtherMatchNav] = React.useState(false);
    const [similarityClass, setSimilarityClass] = React.useState(classes.noMatch);
    const [nextMatch, setNextMatch] = React.useState("");
    const [nextMatchIndex, setNextMatchIndex] = React.useState(0);
    const [matchTabDisabled, setMatchTabDisabled] = React.useState(false);
    const [isMatchedJob, setIsMatchedJob] = React.useState(false);
    useEffect(() => {
        if(typeof line.matchingJob.uid !== "undefined"){
          setShownJob(line.matchingJob);
          setOtherMatches(line.matchingJob.otherMatches);
          setOtherMatchesCount(line.matchingJob.otherMatches.length);
          setIsMatchedJob(line.matchingJob.matchedJob);
          if(line.matchingJob.otherMatches.length > 0){
            setNextMatch(line.matchingJob.otherMatches[nextMatchIndex]);
          }
          handleSimilarity(line.matchingJob);
          setShowOtherMatchNav(true);
        }
        else {
            setMatchTabDisabled(true);
            setValue(1);
        }
        if(line.invoiceLineMessages.length > 0){
            setValue(2);
            setShowOtherMatchNav(false);
        }


    }, []);

    function handleSimilarity(job) {
        if(parseFloat(job.similarity) >= 0.8){
            setSimilarityClass(classes.completeMatch)
        }else {
            setSimilarityClass(classes.partialMatch)
        }
    }
    function handleChange(event, newValue) {
        setValue(newValue);
        if(newValue === 0){
            setShowOtherMatchNav(true);
        }else {
            setShowOtherMatchNav(false);
        }
    }

    function handleChangeIndex(index) {
        setValue(index);
    }
    function handleOtherMatchesNav(){
        //console.log("otherMatches.length", otherMatches.length);
        //console.log("nextMatchIndex", nextMatchIndex);
        if(otherMatches.length > nextMatchIndex){
            setShownJob(otherMatches[nextMatchIndex]);
            props.onMatchSelect(otherMatches[nextMatchIndex], line);
            handleSimilarity(otherMatches[nextMatchIndex]);
            if(otherMatches.length == (nextMatchIndex + 1) ){
                setNextMatch(line.matchingJob);
            }
            else {
                setNextMatch(otherMatches[nextMatchIndex+1]);
            }
            setNextMatchIndex(nextMatchIndex + 1);
        }
        else {
            setShownJob(line.matchingJob);
            props.onMatchSelect(line.matchingJob, line);
            handleSimilarity(line.matchingJob);
            setNextMatch(otherMatches[0])
            setNextMatchIndex(0);
        }

    }
    return (
        <div>
            <Card className={classes.card} key={line.id+"_right_card"}>
                <CardContent className={`${classes.cardContentWithTabs}` }>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#CACACA"
                                }
                            }}
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Match" {...a11yProps(0)} disabled={matchTabDisabled} className={similarityClass}/>
                            <Tab label="Find or Create" {...a11yProps(1)} disabled={isMatchedJob}/>
                            <Tab label="Discuss" {...a11yProps(2)} disabled={isMatchedJob}/>
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0} dir={theme.direction} className={`${classes.tabPanel} ${similarityClass}`}>
                            {shownJob ? (
                            <MatchTabDetail key={shownJob.uid} line={line} job={shownJob} otherMatches={otherMatches} otherMatchesCount={otherMatchesCount}/>
                                ) : (<div></div>)}
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <FindTab line={line} hideRow={props.hideRow} handleReconcileJob={props.handleReconcileJob}/>
                        </TabPanel>
                        <TabPanel value={value} index={2} dir={theme.direction}>
                            <DiscussTab line={line} discussMessage={props.discussMessage} enqueueSnackbar={props.enqueueSnackbar}/>
                        </TabPanel>
                    </SwipeableViews>
                </CardContent>
            </Card>
            {otherMatchesCount > 0 && showOtherMatchNav ? (
            <div className={classes.otherMatchesNavButtonDiv}>
                <Button onClick={handleOtherMatchesNav} size="small" className={classes.otherMatchesNavButton}>
                    <Typography className={classes.otherMatchesNav}>
                        {nextMatch.name} <Icon className={clsx(classes.otherMatchesNavIcon, 'fa fa-chevron-right')} />
                    </Typography>
                </Button>
            </div>) : (<div></div>)
            }
        </div>
    );
}