import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CurrencyFormat from "../../_helpers/CurrencyFormat";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    keyIndicatorsContainer: {
        marginTop: theme.spacing(2),
    },
    keyIndicatorsTableRow: {
        borderBottom: "2px solid #807a7a",
    },
    keyIndicatorsTableThCell: {
        fontWeight: 600
    },
}));

const TotalPurchases = (props) => {
    let job = props.job;
    let value =  (parseFloat(job.totalPurchases)).toFixed(2);
    return <CurrencyFormat value={value}/>
};
const TotalInvoices = (props) => {
    let job = props.job;
    let value = (parseFloat(job.totalInvoices)).toFixed(2);
    return <CurrencyFormat value={value}/>
};
const MarginCurrentlyReconciled = (props) => {
    let job = props.job;
    let value = (parseFloat(job.totalInvoices) - parseFloat(job.totalPurchases)).toFixed(2);
    return <CurrencyFormat value={value}/>
};
const MarginCurrentlyReconciledPercent = (props) => {
    let job = props.job;
    let value = ((parseFloat(job.totalInvoices) - parseFloat(job.totalPurchases))/parseFloat(job.totalInvoices) *100).toFixed(2);
    return +value || 0;
};
const MissingRevenue = (props) => {
    let job = props.job;
    let value = (parseFloat(job.practicalValue) - parseFloat(job.totalInvoices)).toFixed(2);
    return <CurrencyFormat value={value}/>
};
const MarginRealised = (props) => {
    let job = props.job;
    let value = (parseFloat(job.practicalValue) - parseFloat(job.totalPurchases)).toFixed(2);
    return <CurrencyFormat value={value} highlight={true}/>
};
const MarginRemaining = (props) => {
    let job = props.job;
    let value = (parseFloat(job.practicalValue) - parseFloat(job.totalPurchases)).toFixed(2);
    return <CurrencyFormat value={value} highlight={true}/>
};
const MarginRealisedPercent = (props) => {
    let job = props.job;
    let value = ((parseFloat(job.practicalValue) - parseFloat(job.totalPurchases))/parseFloat(job.practicalValue) * 100).toFixed(2);
    return +value || 0;
};

const MarginRemainingPercent = (props) => {
    let job = props.job;
    return ((parseFloat(job.practicalValue) - parseFloat(job.totalPurchases))/parseFloat(job.practicalValue) * 100).toFixed(2);
};
const MarginActual = (props) => {
    let job = props.job;
    let invoices = parseFloat(job.totalInvoices) + parseFloat(job.totalInvoicesAfterCompletion);
    let purchases = parseFloat(job.totalPurchases) + parseFloat(job.totalPurchasesAfterCompletion);
    let value = (invoices - purchases).toFixed(2);
    return <CurrencyFormat value={value} highlight={true}/>
};

const MarginActualPercent = (props) => {
    let job = props.job;
    let invoices = parseFloat(job.totalInvoices) + parseFloat(job.totalInvoicesAfterCompletion);
    let purchases = parseFloat(job.totalPurchases) + parseFloat(job.totalPurchasesAfterCompletion);
    return ((invoices - purchases)/invoices * 100).toFixed(2);
};

const RemainingBudget = (props) => {
    let job = props.job;
    let value = ((parseFloat(job.practicalValue) - (parseFloat(job.value) * (parseFloat(job.targetMargin)/100))) - parseFloat(job.totalPurchases)).toFixed(2);
    return <CurrencyFormat value={value} highlight={true}/>;
};
const TrailingItems = (props) => {
    let job = props.job;
    let value = (parseFloat(job.totalPurchasesAfterCompletion)).toFixed(2);
    return <CurrencyFormat value={value}/>
};
const TrailingMargin = (props) => {
    let job = props.job;
    let value = ((parseFloat(job.practicalValue) - parseFloat(job.totalPurchases))  - parseFloat(job.totalPurchasesAfterCompletion)).toFixed(2);
    return <CurrencyFormat value={value} highlight={true}/>
};

const TrailingMarginPercent = (props) => {
    let job = props.job;
    let value = (
        ((parseFloat(job.practicalValue) - parseFloat(job.totalPurchases))  - parseFloat(job.totalPurchasesAfterCompletion))
        / (parseFloat(job.practicalValue))
        * 100
    ).toFixed(2);
    return value
};

export default function JobKeyIndicatiors(props) {
    const classes = useStyles();
    const [job, setJob] = React.useState();
    useEffect(() => {
        // Update the document title using the browser API
       setJob(props.job);
    }, [props.isJobUpdated]);
    return (
        <div>
            <Typography variant="h6">Economics</Typography>
            {job ?
                <Grid container className={classes.keyIndicatorsGridContainer} spacing={3}>
                    <Grid item xs={4} className={classes.keyIndicatorsGridItem}>
                        <Table className={classes.keyIndicatorsTable}>
                            <TableBody>
                                <TableRow className={classes.keyIndicatorsTableRow}>
                                    <TableCell component="th" className={classes.keyIndicatorsTableThCell}>
                                        <Typography>Key Indicators</Typography>
                                    </TableCell>
                                    <TableCell component="th"></TableCell>
                                </TableRow>
                                <TableRow className={classes.keyIndicatorsTableRow}>
                                    <TableCell>Margin</TableCell>
                                    <TableCell align="left">
                                        {job.completedAt ?
                                            <React.Fragment><MarginRealisedPercent job={job}/>%</React.Fragment>:
                                            <React.Fragment><MarginRemainingPercent job={job}/>%</React.Fragment>
                                        }
                                    </TableCell>
                                </TableRow>
                                {job.completedAt ?
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>Profit/(Loss)</TableCell>
                                        <TableCell align="left"><MarginActual job={job}/></TableCell>
                                    </TableRow>
                                    :
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>Yet To Invoice</TableCell>
                                        <TableCell align="left"><MissingRevenue job={job}/></TableCell>
                                    </TableRow>
                                }
                                <TableRow className={classes.keyIndicatorsTableRow}>
                                    <TableCell>Target Margin</TableCell>
                                    <TableCell align="left">{job.targetMargin}%</TableCell>
                                </TableRow>
                                <TableRow className={classes.keyIndicatorsTableRow}>
                                    <TableCell> {job.completedAt? <span>Budget Variance</span> :  <span>'Remaining Expense Budget'</span>}</TableCell>
                                    <TableCell align="left"><RemainingBudget job={job}/></TableCell>
                                </TableRow>
                                {job.completedAt && (job.totalInvoicesAfterCompletion > 0 || job.totalPurchasesAfterCompletion > 0)?
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>Items Post Completion</TableCell>
                                        <TableCell align="left"><TrailingItems job={job}/></TableCell>
                                    </TableRow>: null}
                                {job.completedAt && (job.totalInvoicesAfterCompletion > 0 || job.totalPurchasesAfterCompletion > 0)?
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>Trailing Margin</TableCell>
                                        <TableCell align="left"><TrailingMarginPercent job={job}/>%</TableCell>
                                    </TableRow>: null}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={4} className={classes.keyIndicatorsGridItem}>
                        <Table className={classes.keyIndicatorsTable}>
                            <TableBody>
                                <TableRow className={classes.keyIndicatorsTableRow}>
                                    <TableCell component="th" className={classes.keyIndicatorsTableThCell}>
                                        {job.completedAt ? <Typography>At Completion</Typography>: <Typography>When Fully Invoiced</Typography>}
                                    </TableCell>
                                    <TableCell component="th"></TableCell>
                                </TableRow>
                                <TableRow className={classes.keyIndicatorsTableRow}>
                                    <TableCell>{job.completedAt ? <Typography>Invoices Sent</Typography>:<Typography>Job Value</Typography>}</TableCell>
                                    <TableCell align="left"><CurrencyFormat value={job.practicalValue}/></TableCell>
                                </TableRow>
                                <TableRow className={classes.keyIndicatorsTableRow}>
                                    <TableCell>- Costs Incurred</TableCell>
                                    <TableCell align="left"><TotalPurchases job={job}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.keyIndicatorsTableThCell}>Margin</TableCell>
                                    <TableCell align="left" className={classes.keyIndicatorsTableThCell}>
                                        {job.completedAt ?
                                            <MarginRealised job={job}/>:
                                            <MarginRemaining job={job}/>
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="left" className={classes.keyIndicatorsTableThCell}>
                                        {job.completedAt ?
                                            <React.Fragment><MarginRealisedPercent job={job}/>%</React.Fragment>
                                            : <React.Fragment><MarginRemainingPercent job={job}/>%</React.Fragment>
                                        }
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={4} className={classes.keyIndicatorsGridItem}>
                        {!job.completedAt ?
                            <Table className={classes.keyIndicatorsTable}>
                                <TableBody>
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell component="th" className={classes.keyIndicatorsTableThCell}>
                                            <Typography>Currently Reconciled</Typography>
                                        </TableCell>
                                        <TableCell component="th"></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>Invoices Sent</TableCell>
                                        <TableCell align="left"><TotalInvoices job={job}/></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>- Costs Incurred</TableCell>
                                        <TableCell align="left"><TotalPurchases job={job}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.keyIndicatorsTableThCell}>Margin</TableCell>
                                        <TableCell align="left" className={classes.keyIndicatorsTableThCell}><MarginCurrentlyReconciled job={job}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="left" className={classes.keyIndicatorsTableThCell}><MarginCurrentlyReconciledPercent job={job}/>%</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>:
                            <React.Fragment>
                            {job.completedAt && (job.totalInvoicesAfterCompletion > 0 || job.totalPurchasesAfterCompletion > 0)?
                            <Table className={classes.keyIndicatorsTable}>
                                <TableBody>
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell component="th" className={classes.keyIndicatorsTableThCell}>
                                            <Typography>Post Completion</Typography>
                                        </TableCell>
                                        <TableCell component="th"></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>Margin</TableCell>
                                        <TableCell align="left"><MarginRealised job={job}/></TableCell>
                                    </TableRow>
                                    <TableRow className={classes.keyIndicatorsTableRow}>
                                        <TableCell>- Trailing Items</TableCell>
                                        <TableCell align="left"><TrailingItems job={job}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.keyIndicatorsTableThCell}>Trailing Margin</TableCell>
                                        <TableCell align="left" className={classes.keyIndicatorsTableThCell}><TrailingMargin job={job}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell align="left" className={classes.keyIndicatorsTableThCell}><TrailingMarginPercent job={job}/>%</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>: null}
                                </React.Fragment>
                        }
                    </Grid>
                </Grid>: null}
        </div>
    )
}