import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from "@material-ui/core/Icon";
import clsx from 'clsx';
import { loadCSS } from 'fg-loadcss';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import OptionsDropDown from "./OptionsDropDown";
import Collapse from "@material-ui/core/Collapse";
import NoteForm from "./reconcile/NoteForm";
import FileUpload from "./reconcile/FileUpload";
import FileList from "./reconcile/FileList";
import InvoiceTooltip from "./reconcile/InvoiceTooltip";
import AccountEdit from "./reconcile/AccountEdit";
import CurrencyFormat from "./_helpers/CurrencyFormat";

const useStyles = makeStyles(theme =>({
    card: {
        minWidth: 275,
        backgroundColor: "rgb(248, 248, 248)",
        border: "1px solid rgb(214, 218, 222);",
    },
    cardContent: {
        "&:last-child": {
            paddingBottom: 0
        }
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,

    },
    jobTitle: {
        fontSize: "16px",
        fontWeight: 500,
        marginBottom: 12
    },
    icon: {
       fontSize: "16px",
        marginLeft: '5px',
    },
    invoiceDetail: {
        borderRight: '1px solid rgb(214, 218, 222);'
    },
    typeDetail: {
        fontSize: "12px"
    },
    typeAmount: {
        fontSize: "12px",
        fontWeight: 700
    },
    buttonLeftIcon: {
        marginRight: "5px",
        fontWeight: 500,
        fontSize: "12px"
    },
    moreBtnGrid: {
        textAlign: "right"
    },
    collapseContainer: {
        paddingTop: "10px",
        paddingBottom: "10px"
    }

}));

export default function InvoiceLineCard(props) {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const [line, setLine] = React.useState(props.line);
    const [files, setFiles] = React.useState(props.line.invoiceLineFiles);
    const [isFilesLoading, setIsFilesLoading] = React.useState(false);
    const [openNotes, setOpenNotes] = React.useState(false);
    const [openFiles, setOpenFiles] = React.useState(false);
    function handleNoteClick() {
        setOpenFiles(false);
        setOpenNotes(!openNotes);
    }
    function handleFileClick() {
        setOpenNotes(false);
        setOpenFiles(!openFiles);
    }
    function handleNewFile(newFile) {
        let newFiles = files;
        setIsFilesLoading(true);
        //console.log(newFiles);
        newFiles.push(newFile);
        setFiles(newFiles);
        setIsFilesLoading(false);
    }
    return (
        <div>
            <OptionsDropDown handleDeleteLine={props.handleDeleteLine} line={line}/>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid container spacing={5}>
                        <Grid item xs={9} className={classes.invoiceDetail}>
                            <Typography variant="subtitle1" component="h2" className={classes.jobTitle}>
                                {line.invoice.invoiceType == "ACCREC" ? <span>{line.displayDescription}</span> : <span>{line.description}</span>}
                                <Link href={line.invoice.xeroUrl} target="_blank">
                                    <Icon className={clsx(classes.icon, 'fa fa-external-link-alt')} />
                                </Link>
                            </Typography>
                            <Typography className={classes.typeDetail} color="textSecondary">
                                Created: {line.invoice.invoiceDate}
                            </Typography>
                            <AccountEdit key={line.id} line={line} enqueueSnackbar={props.enqueueSnackbar}/>
                            <Typography className={classes.typeDetail} color="textSecondary">
                                Contact: {line.invoice.contactName}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.typeAmount}>
                                <CurrencyFormat value={line.lineAmountExGst}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid style={{paddingBottom: "10px"}} container spacing={5}>
                        <Grid item xs={3}>
                            <Button size="small" onClick={handleNoteClick}><Icon className={clsx(classes.buttonLeftIcon, 'fa fa-plus')} /> Notes</Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button size="small" onClick={handleFileClick}><Icon className={clsx(classes.buttonLeftIcon, 'fa fa-plus')} /> Files</Button>
                        </Grid>
                        <Grid item xs={3} className={`${classes.invoiceDetail} ${classes.moreBtnGrid}`}>
                            <InvoiceTooltip line={line} />
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                    </Grid>
                    <Collapse in={openNotes} className={classes.collapseContainer} timeout="auto" unmountOnExit>
                        <NoteForm line={line} note={props.note} enqueueSnackbar={props.enqueueSnackbar} handleNoteChange={props.handleNoteChange}/>
                    </Collapse>
                    <Collapse in={openFiles} className={classes.collapseContainer} timeout="auto" unmountOnExit>
                        <FileList key={`${line.id}_files`} files={files} isFilesLoading={isFilesLoading}/>
                        <FileUpload line={line} handleNewFile={handleNewFile}/>
                    </Collapse>
                </CardContent>

            </Card>
        </div>
    );
}