import React, {useEffect} from "react";
import MUIDataTable from "mui-datatables";
import API from "../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import axios from 'axios';
import Moment from 'react-moment';
import JobDetailPanel from "./JobDetailPanel";
import FormatCurrency from 'react-format-currency';
import CurrencyFormat from "../_helpers/CurrencyFormat";
import JobListCustomToolbarSelect from "./components/JobListCustomToolbarSelect";
import JobMergePanel from "./components/JobMergePanel";

function StatusField(props) {
    return (
        <div>
            { props.value !== undefined ?
                props.value === 0?
                    <Typography color="secondary">Active</Typography>
                    :
                    <Typography color="primary">Completed</Typography>
                : null
            }
        </div>
    )
}
const JTC = ["id", "name", "ownerName", "ownerEmail", "createdAt", "practicalValue", "missing", "margin", "totalInvoices", "totalPurchases", "totalInvoicesAfterCompletion", "totalPurchasesAfterCompletion", "trailing", "status"];


const MissingRevenue = (props) => {
    let row = props.row;
    let value = (parseFloat(row[JTC.indexOf('practicalValue')]) - parseFloat(row[JTC.indexOf('totalInvoices')])).toFixed(2);
    if(isNaN(value)){
        return ''
    }
    if(+value) {
        return <CurrencyFormat value={value}/>
    }
    else {
        return <CurrencyFormat value={value}/>
    }
};

const MarginRemaining = (props) => {
    let row = props.row;
    let value = (parseFloat(row[JTC.indexOf('practicalValue')]) - parseFloat(row[JTC.indexOf('totalPurchases')])).toFixed(2);
    if(+value) {
        return <CurrencyFormat value={value} highlight={true}/>
    }
    else {
        return ''
    }
};
const MarginRealisedPercent = (props) => {
    let row = props.row;
    let value = ((parseFloat(row[JTC.indexOf('practicalValue')]) - parseFloat(row[JTC.indexOf('totalPurchases')])) / parseFloat(row[JTC.indexOf('practicalValue')]) *100).toFixed(2);
    if(+value) {
        return `${value}%`
    }
    else {
        return ''
    }
};

const TrailingMargin = (props) => {
    let row = props.row;
    let margin = (parseFloat(row[JTC.indexOf('practicalValue')]) - parseFloat(row[JTC.indexOf('totalPurchases')])).toFixed(2);
    let trailing = ((parseFloat(row[JTC.indexOf('practicalValue')]) - parseFloat(row[JTC.indexOf('totalPurchases')]))  - parseFloat(row[JTC.indexOf('totalPurchasesAfterCompletion')])).toFixed(2);
    if(+trailing && (margin !== trailing)) {
        return <CurrencyFormat value={trailing} highlight={true}/>
    }
    else {
        return ''
    }
};


export default function JobList() {
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(1);
    const [data, setData] = React.useState([{name: "Loading Data"}]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [filterParams, setFilterParams] = React.useState({});
    const [sortParams, setSortParams] = React.useState({});
    const [searchParams, setSearchParams] = React.useState({});
    const [searchText, setSearchText] = React.useState(null);
    const [isFiltered, setIsFiltered] = React.useState(false);
    const [columns, setColumns] = React.useState([
        {
            name: "id",
            label: "Id",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Job Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "ownerName",
            label: "Owner Name",
            options: {
                filter: true,
                filterType: 'textField',
                sort: false,
            }
        },
        {
            name: "ownerEmail",
            label: "Owner Email",
            options: {
                display: "false",
                filter: false,
                filterType: 'textField',
                sort: false,
            }
        },
        {
            name: "createdAt",
            label: "Created",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ?
                        <Moment format="D MMM YYYY">
                            {value}
                        </Moment>
                        : null

                )
            }
        },

        {
            name: "practicalValue",
            label: "Value",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    value ?
                     <CurrencyFormat value={value} /> : null

                )
            }
        },
        {
            name: "missing",
            label: "Yet to invoice",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    tableMeta.rowData?
                        <MissingRevenue row={tableMeta.rowData}/> : null

                )
            }
        },
        {
            name: "margin",
            label: "Margin",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    tableMeta.rowData?
                   <MarginRealisedPercent row={tableMeta.rowData}/> : null

                )
            }
        },

        {
            name: "totalInvoices",
            label: "totalInvoices",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalPurchases",
            label: "totalPurchases",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalInvoicesAfterCompletion",
            label: "totalInvoicesAfterCompletion",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalPurchasesAfterCompletion",
            label: "totalPurchasesAfterCompletion",
            options: {
                display: "excluded",
                filter: false,
                sort: false,
            }
        },

        {
            name: "trailing",
            label: "Trailing Margin",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    tableMeta.rowData?
                        <TrailingMargin row={tableMeta.rowData}/> : null

                )
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                filterOptions: {
                    names: ['Active', 'Completed']
                },
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <StatusField value={value} tableMeta={tableMeta} updateValue={updateValue} />
                )
            }
        },
    ]);

    const [mergeOpen, setMergeOpen] = React.useState(false);
    const [mergeData, setMergeData] = React.useState(null);

    const handleMergeOpen = () => {
        setMergeOpen(true);

    };
    const handleMergeData = (selected_ids) => {
        const selected_data = data.filter(row => selected_ids.includes(row.id))
        setMergeData(selected_data);
    };
    useEffect(() => {
        // Update the document title using the browser API
        if (mergeData) {
            handleMergeOpen();
        }

    }, [mergeData]);

    const handleMergeClose = () => {
        setMergeOpen(false);
        fetchData();
    };

    useEffect(() => {
        // Update the document title using the browser API
        fetchData();

    }, [page, pageSize]);
    useEffect(() => {
        if(filterParams !== {}){
            columns.map(function (column, i) {
                if(filterParams[column.name] !== undefined){
                    column.options.filterList = [filterParams[column.name]];
                }
                else {
                    column.options.filterList = [];
                    setPage(0);
                }
            });
            setColumns(columns);
        }
        fetchData();
    }, [filterParams]);

    useEffect(() => {
        // Update the document title using the browser API
        if(sortParams !== {}){
            columns.map(function (column, i) {
                if(sortParams["o"] !== undefined){
                    if(sortParams["o"].includes(column.name)) {
                        column.options.sortDirection = sortParams["o"][0] === "-" ? "desc" : "asc";
                    }
                }
                else {
                    setPage(0);
                }
            });
            setColumns(columns);
        }
        fetchData();

    }, [sortParams]);

    useEffect(() => {
        // Update the document title using the browser API
        if(searchParams !== {}){
            setColumns(columns);
        }
        fetchData();

    }, [searchParams]);

    let CancelToken = axios.CancelToken;
    let cancel = null;
    async function fetchData(){
        if(cancel){
            cancel();
        }
        setIsLoading(true);
        let url = `job-list/`;
        let params = {
            page_size: pageSize,
            page: page+1,
        };
        params = Object.assign(params, filterParams);
        params = Object.assign(params, sortParams);
        params = Object.assign(params, searchParams);
        await API({
            method: "get",
            url: url,
            params: params,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            })
        })
            .then(function (response) {
                setCount(response.data.count);
                setData(response.data.results);
                setIsLoading(false);

            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }
    function changePage(page) {
        setPage(page);
    }
    function changeRowsPerPage(rows) {
        setPageSize(rows);
    }
    function filterChange(columns, filterList) {
        let filter = {};
        columns.map(function(column, i){
           if(column.filter){
               if(filterList[i].length > 0){
                   filter[column.name] = filterList[i][0]
               }
           }
        });

        setFilterParams(filter);
    }
    const options = {
        selectableRows: 'multiple',
        filter: true,
        responsive: 'scrollFullHeight',
        serverSide: true,
        count: count,
        page: page,
        searchText: searchText,
        rowsPerPageOptions: [10, 25, 50],
        expandableRows: true,
        expandableRowsOnClick: true,
        onTableChange: (action, tableState) => {

            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want
            //console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    changePage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    changeRowsPerPage(tableState.rowsPerPage);
                    break;
                case 'filterChange':
                    filterChange(tableState.columns, tableState.filterList);
                    break;
                case 'resetFilters':
                    filterChange(tableState.columns, tableState.filterList);
                    break;
            }
        },
        onColumnSortChange: (changedColumn, direction) => {
            let sort_by = changedColumn;
            if(direction === "descending"){
                sort_by = `-${changedColumn}`;
            }
            setSortParams({"o": sort_by});
        },
        onSearchChange: (searchText) => {
            setSearchText(searchText);
            setSearchParams({"search": searchText});
        },
        // onFilterChange: (changedColumn, filterList) => {
        //     console.log(changedColumn, filterList);
        // }
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <JobDetailPanel jobId={rowData[0]} isList={true} rowData={rowData} rowMeta={rowMeta} tableFetchData={fetchData} />
                    </TableCell>
                </TableRow>
            );
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return (
                <JobListCustomToolbarSelect selectedRows={selectedRows} displayData={displayData} handleMergeData={handleMergeData}/>
            );
        },
        isRowSelectable: (dataIndex) => {
            try {
                return data[dataIndex].linkedJobs.length === 0;
            }
            catch (e) {
                return false
            }
        },
        onTableInit: (action, tableState) => {
            //console.log("init success");
            //console.log(action);
            //console.log(tableState);
        }
    };

    return (
        <div>

            <MUIDataTable
                title={<Typography variant="h5">
                            Jobs
                            {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                        </Typography>
                    }
                data={data}
                columns={columns}
                options={options} />
             <JobMergePanel open={mergeOpen} onClose={handleMergeClose} data={mergeData}/>
        </div>
    );
}