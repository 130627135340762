import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {green} from "@material-ui/core/colors";
import API from "../../api";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CurrencyFormat from "../../_helpers/CurrencyFormat";
import Moment from "react-moment";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from '@material-ui/icons/Check';
import Edit from "@material-ui/icons/Edit";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    summaryContainer:{
        marginTop: theme.spacing(2),
    },
    summaryTable: {
        borderColor: '#807a7a',
        borderStyle: 'solid',
        backgroundColor: "rgb(208, 206, 206)"
    },
    summaryTableRow: {
        borderColor: '#807a7a',
        borderStyle: 'solid'
    },
    summaryTableThCell:{
        borderRight: "2px solid #807a7a",
        fontWeight: 600
    },
    summaryTableCell:{
        borderRight: "2px solid #807a7a",
    }
}));

export default function JobDetailSummary(props) {
    const classes = useStyles();
    const [job, setJob] = React.useState(props.job);
    const [jobValues, setJobValues] = React.useState({});
    useEffect(() => {
        // Update the document title using the browser API
        setJob(props.job);
        if(props.job){
            setJobValues({
                client: props.job.client,
                ownerName: props.job.ownerName,
                value: props.job.value,
                source: props.job.source,
                completedAt: props.job.completedAt
            });
        }

    }, [props.isJobUpdated]);
    const handleCompletedDateChange = date => {
        setJobValues({ ...jobValues, ['completedAt']: date });
    };
    const [editMode, setEditMode] = React.useState(false);
    async function saveJob() {
        let url = `job-detail/${props.job.id}/`;
        await API.patch(url, jobValues)
            .then(function (response) {
                props.handleJobUpdate(response.data);
                props.rowData[13].props.updateValue(response.data["status"]);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function handleEditClick() {
        setEditMode(true);
    }
    function handleSaveClick() {
        saveJob();
        setEditMode(false);
    }
    const handleChange = name => event => {
        setJobValues({ ...jobValues, [name]: event.target.value });
    };
    return (
        <div>
            <Typography variant="h6">
                Summary Details
                {!editMode ?
                <IconButton size="small" onClick={handleEditClick}>
                    <Edit fontSize="small" className={classes.editBtn}/>
                </IconButton>:
                <IconButton size="small" onClick={handleSaveClick}>
                    <CheckIcon fontSize="medium" className={classes.saveBtn}/>
                </IconButton>
                }
            </Typography>
            {job ?
                !editMode ?
                    <Table className={classes.summaryTable} size="small">
                    <TableBody>
                        <TableRow className={classes.summaryTableRow}>
                            <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                Client
                            </TableCell>
                            <TableCell align="left" className={classes.summaryTableCell}>
                                {jobValues.client}
                            </TableCell>
                            <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                Owner
                            </TableCell>
                            <TableCell align="left" className={classes.summaryTableCell}>{jobValues.ownerName}</TableCell>
                        </TableRow>
                        <TableRow className={classes.summaryTableRow}>
                            <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                Value
                            </TableCell>
                            <TableCell align="left" className={classes.summaryTableCell}><CurrencyFormat value={jobValues.value}/></TableCell>
                            <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                Source
                            </TableCell>
                            <TableCell align="left" className={classes.summaryTableCell}>
                                {props.job.url ?
                                    <Link href={props.job.url} target="_blank">{jobValues.source}</Link>
                                    :
                                    <Typography>{jobValues.source}</Typography>
                                }
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.summaryTableRow}>
                            <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                Created Date
                            </TableCell>
                            <TableCell align="left" className={classes.summaryTableCell}>
                                <Moment format="DD/MM/YYYY">
                                    {job.createdAt}
                                </Moment>
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                Completed Date
                            </TableCell>
                            <TableCell align="left" className={classes.summaryTableCell}>
                                {jobValues.completedAt ?
                                    <Moment format="DD/MM/YYYY">
                                        {jobValues.completedAt}
                                    </Moment>
                                    : null}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>:
                    <Table className={classes.summaryTable} size="small">
                        <TableBody>
                            <TableRow className={classes.summaryTableRow}>
                                <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                    Client
                                </TableCell>
                                <TableCell align="left" className={classes.summaryTableCell}>
                                    <TextField
                                        id="standard-client"
                                        className={classes.textField}
                                        value={jobValues.client}
                                        fullWidth={true}
                                        onChange={handleChange('client')}
                                        margin="dense"
                                    />
                                </TableCell>
                                <TableCell className={classes.summaryTableThCell} component="th" scope="row">
                                    Owner
                                </TableCell>
                                <TableCell align="left" className={classes.summaryTableCell}>
                                    <TextField
                                        id="standard-ownerName"
                                        className={classes.textField}
                                        value={jobValues.ownerName}
                                        onChange={handleChange('client')}
                                        margin="dense"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.summaryTableRow}>
                                <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                    Value
                                </TableCell>
                                <TableCell align="left" className={classes.summaryTableCell}>
                                    {!jobValues.completedAt ?
                                        <TextField
                                            id="standard-value"
                                            className={classes.textField}
                                            value={jobValues.value}
                                            onChange={handleChange('value')}
                                            margin="dense"
                                            type="number"
                                        /> :
                                        <CurrencyFormat value={props.job.practicalValue}/>
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                    Source
                                </TableCell>
                                <TableCell align="left" className={classes.summaryTableCell}>
                                    <TextField
                                        id="standard-source"
                                        className={classes.textField}
                                        value={jobValues.source}
                                        onChange={handleChange('source')}
                                        margin="dense"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.summaryTableRow}>
                                <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                    Created Date
                                </TableCell>
                                <TableCell align="left" className={classes.summaryTableCell}>
                                    <Moment format="DD/MM/YYYY">
                                        {job.createdAt}
                                    </Moment>
                                </TableCell>
                                <TableCell component="th" scope="row" className={classes.summaryTableThCell}>
                                    Completed Date
                                </TableCell>
                                <TableCell align="left" className={classes.summaryTableCell}>
                                    {/*{job.completedAt ?*/}
                                    {/*    <Moment format="DD/MM/YYYY">*/}
                                    {/*        {job.completedAt}*/}
                                    {/*    </Moment>*/}
                                    {/*    : null}*/}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                            margin="dense"
                                            id="date-picker-inline"
                                            label=""
                                            autoOk={true}
                                            value={jobValues.completedAt}
                                            onChange={handleCompletedDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                : null}
        </div>
    )
}