import React, {useCallback, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {LoginAPI} from '../components/api'
import {Redirect} from "react-router-dom";
import DocumentTitle from "react-document-title";


const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    errorMsg: {
        textAlign: 'center',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function LoginView(props) {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    React.useEffect(() => {
        props.handleNavbar(true);
    }, []);
    const validateForm = () => {return email.length > 0 && password.length > 0};
    const handleEmailChange = event => {setEmail(event.target.value)};
    const handlePasswordChange = event => {setPassword(event.target.value)};
    const handleSubmit = async event => {
        event.preventDefault();
        setLoading(true);
        await LoginAPI.post('/token/', {
            username: email,
            password: password
        })
            .then(function (response) {
                //console.log(response);
                localStorage.setItem('access_token', JSON.stringify(response.data.access));
                localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh));
                localStorage.setItem('userAccess', JSON.stringify(response.data.userAccess));
                setLoading(false);
                props.handleNavbar(false);
                setIsLoggedIn(true);
            })
            .catch(function (error) {
                console.log(error);
                setIsInvalid(true);
                setLoading(false);
            });
    };
    return (
        <DocumentTitle title="Login | JobRec">
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>

                    <form className={classes.form} noValidate  onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleEmailChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handlePasswordChange}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <FormHelperText id="my-helper-text" error={isInvalid} hidden={!isInvalid} className={classes.errorMsg}>Incorrect email or password</FormHelperText>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!validateForm()||loading}
                            color="primary"
                            className={classes.submit}

                        >
                            Sign In
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                {isLoggedIn ? <Redirect to="/"/> : null}
            </Container>
        </DocumentTitle>
    );
}