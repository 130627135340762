import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import API from "../api";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        width: "100%",
        marginTop: 0,
    },
    dense: {
    },
    menu: {
        width: 200,
    },
    messageBox: {
        width: "100%"
    },
    submitButton: {
        marginTop: "5px",
    }
}));
const MessageSchema = Yup.object().shape({
    message: Yup.string(),
});
export default function NoteForm(props) {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    const [messageId, setMessageId] = React.useState(null);
    useEffect(() => {
        if(props.note){
            setMessage(props.note.message);
            setMessageId(props.note.id);
        }
    }, []);
    async function createOrUpdateMessage(values, actions) {
        values.invoiceLine = props.line.id;
        let method = "post";
        let url = "invoice-line-notes/";
        if(messageId){
            if(values.message === ""){
                method = "delete";
                url += `${messageId}/`;
            }
            else{
                method = "patch";
                url += `${messageId}/`;
            }
        }
        await API({method: method, url:url, data:values})
            .then(function (response) {
                if(response.statusText === "Created"){
                    props.enqueueSnackbar("Note Created", {variant: "success"});
                    setMessage(response.data.message);
                    setMessageId(response.data.id);
                    props.handleNoteChange(response.data);
                }
                else if(response.statusText === "No Content"){
                    props.enqueueSnackbar("Note Deleted", {variant: "warning"});
                    setMessage("");
                    setMessageId(null);
                    props.handleNoteChange(null);
                }
                else {
                    props.handleNoteChange(response.data);
                    props.enqueueSnackbar("Note Saved", {variant: "success"});
                }
                actions.setSubmitting(false);
            })
            .catch(function (error) {
                console.log(error);
                actions.setSubmitting(false);
            });
    }
    return (
        <Formik
            initialValues={{
                message: message
            }}
            enableReinitialize={true}
            validationSchema={MessageSchema}
            onSubmit={(values, actions ) => {
                setTimeout(() => {
                    createOrUpdateMessage(values, actions);
                    setMessage(values.message);
                    //console.log(values);
                }, 400);
            }}
        >
            {({ errors, touched , isSubmitting }) => (
                <Form className={classes.container} autoComplete="off">
                    <Field
                        id="outlined-dense"
                        label="Message"
                        name="message"
                        className={clsx(classes.textField, classes.dense)}
                        margin="dense"
                        // component={() => <TextareaAutosize className={classes.messageBox} aria-label="minimum height" rows={6} placeholder="Minimum 3 rows" />}
                        component="textarea"
                        rows="6"
                        variant="outlined"
                    />
                    <Button type="submit" color="primary" variant="outlined" className={clsx(classes.button, classes.submitButton)} disabled={isSubmitting}>
                        Save
                    </Button>
                </Form>
            )}
        </Formik>

    );
}