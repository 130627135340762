import React from 'react';
import './App.css';
import NavBar from "./components/NavBar";
import DashboardView from "./containers/DashboardView";
import {makeStyles} from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import ReconcileView from "./containers/ReconcileView";
import LoginView from "./containers/LoginView";
import { PrivateRoute } from "./components/PrivateRoute"
import {useState, useEffect} from "react";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import JobsView from "./containers/JobsView";
import ReportView from "./containers/ReportView";
import DocumentTitle from 'react-document-title';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import Container from "@material-ui/core/Container";
import {Redirect} from "react-router-dom";
import JobDetailView from "./containers/JobDetailView";
import Error404 from "./containers/Error404";
import {hasPermission} from "./components/UserAccess";
import Toolbar from "@material-ui/core/Toolbar";
import ChangePasswordView from "./containers/ChangePasswordView";

addLocaleData([...en]);

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#5ba6fb',
            main: '#0078c8',
            dark: '#004d97',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

function App (props) {

    const [isNavbarHidden, setIsNavbarHidden] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);


    function handleNavbar(isNavbarHidden) {
          setIsNavbarHidden(isNavbarHidden);
      }
      function handleAppLogout(){
          localStorage.clear();
          setIsLoggedIn(false);
      }
      return (
          <DocumentTitle title="JobRec">
          <React.Fragment>
              <IntlProvider locale="en">
                  <ThemeProvider theme={theme}>
                      <SnackbarProvider maxSnack={3}>
                          <div>
                              {!isNavbarHidden && <NavBar handleAppLogout={handleAppLogout} />}
                              <Switch>
                                  <PrivateRoute exact path="/" component={DashboardView} />
                                  {hasPermission('Reconcile') ?
                                      <PrivateRoute exact path="/reconcile" component={ReconcileView}/>:null
                                  }
                                  {hasPermission('Jobs') ?
                                      <PrivateRoute exact path="/jobs" component={JobsView}/> : null
                                  }
                                  {hasPermission('Jobs') ?
                                          <PrivateRoute exact path="/jobs/:id" component={JobDetailView} />:null
                                  }
                                  {hasPermission('Report') ?
                                      <PrivateRoute exact path="/report" component={ReportView}/> : null
                                  }
                                  <PrivateRoute exact path="/change-password" component={ChangePasswordView} handleNavbar={handleNavbar} />
                                  <Route exact path="/login" render={() => <LoginView handleNavbar={handleNavbar}/>}/>
                                  <Route exact path="/error" render={() => <Error404 errorName={'Object Not found OR you may not have permission to view the object'}/>} />
                                  <Route path="*" render={() => <Error404 errorName={'404 ERROR'}/>}  />
                              </Switch>
                          </div>
                          {!isLoggedIn ? <Redirect to="/login"/> : null}
                      </SnackbarProvider>
                  </ThemeProvider>
              </IntlProvider>
          </React.Fragment>
          </DocumentTitle>
      );
}

export default App;
